// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { ScanskuPage } from './scansku.page';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';


const routes: Routes = [
  {
    path: '',
    component: ScanskuPage  
  },
  {path: 'dashboard', loadChildren: '../dashboard/dashboard.module#DashboardPageModule'}
  

];

@NgModule({
  declarations: [
    ScanskuPage    
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    IonicModule,  
    RouterModule.forChild(routes)  
  ],
 
})
export class ScanskuPageModule { }
