import { Platform, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap, map, catchError, retry} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

 
const TOKEN_KEY = 'access_token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  url = environment.url;
  user = null;
  authenticationState = new BehaviorSubject(false); 
  loginError: boolean = false; 
  data: any;
 
  constructor(private http: HttpClient, private helper: JwtHelperService, private storage: Storage,
    private plt: Platform, private alertController: AlertController) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    console.log('checking token')
    this.storage.get(TOKEN_KEY).then(token => {
      console.log(token)
      if (token) {
        let decoded = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
 
        if (!isExpired) {
          this.user = decoded;
          console.log("this is the user: ", this.user)
          this.authenticationState.next(true);
        } else {
          this.storage.remove(TOKEN_KEY);
        }
      }
    });
  }

  register(credentials) {
    return this.http.post(`${this.url}/V2/api/register/`, credentials).pipe(
      catchError(e => {
        this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }
 
  login(credentials) {
    // console.log('AuthService - Login Requested');    
    console.log(credentials)
    return this.http.post(`${this.url}/V2/api/login/`, credentials)
      .pipe(
        tap(res => {          
          // console.log('AUTH DATA RESPONSE', res)
          this.storage.set(TOKEN_KEY, res['token']);
          this.user = this.helper.decodeToken(res['token']);
          // console.log('AUTH USER', this.user);          
          this.authenticationState.next(true);
        }),
        catchError(e => {
          //this.loginPage.loginError = true;
          this.loginError = true;
          // console.log('AUTH FAILED', e) ;  

          this.showAlert(e.error.msg);          
          throw new Error(e);          
        })
      )
  }
 
  async logout() {

    await this.storage.clear().then(() => {
        this.storage.remove(TOKEN_KEY).then(() => {
          this.authenticationState.next(false);         
        });
        
    })
    await this.authenticationState.next(false);
  }
 
  /*getSpecialData() {
    return this.http.get(`${this.url}/V2/api/special/`).pipe(
      catchError(e => {
        let status = e.status;
        if (status === 401) {
          this.showAlert('You are not authorized for this!');
          this.logout();
        }
        throw new Error(e);
      })
    )
  }*/

  resetPassword(credentials) {
    
    return this.http.post(`${this.url}/V2/api/resetpassword/`, credentials)
      .pipe(
          map(res => {
            return res['Message'];
          }),
          catchError(e => {
            this.showAlert(e.error.msg);
            throw new Error(e);
          })
    );
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
 
  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Error',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }


  getUserAccountDetails() {
    // console.log('getUserAccountDetails CALLED');
    return this.http.get(`${this.url}/V2/api/useraccountdetails/?_ts=${+new Date}`)
      .pipe(                             
          tap(res => {
            console.log('User Account Details RESPONSE', res);
            this.storage.set('ACCOUNT_FirstName', res['DataResult']['UserAccount']['FirstName']);
            this.storage.set('ACCOUNT_ID', res['DataResult']['UserAccount']['AccountID']);
            this.storage.set('ACCOUNT_LastName', res['DataResult']['UserAccount']['LastName']);
            this.storage.set('ACCOUNT_Mobile', res['DataResult']['UserAccount']['Mobile']);
            this.storage.set('ACCOUNT_Email', res['DataResult']['UserAccount']['Email']);
            this.storage.set('ACCOUNT_Type', res['DataResult']['UserAccount']['Type']);
             this.storage.set('ACCOUNT_TypeDescription', res['DataResult']['UserAccount']['TypeDescription']);
            this.storage.set('ACCOUNT_Role', res['DataResult']['UserAccount']['Role']); 
            this.storage.set('ACCOUNT_LinkedSuppliers', res['DataResult']['LinkedSuppliers']); 
            this.storage.set('ACCOUNT_LinkedSuppliersCount', res['DataResult']['LinkedSuppliersCount']); 
            this.storage.set('ActiveSupplier', res['DataResult']['UserAccount']['ActiveSupplier']); 
            
            return res['DataResult'];
  
          }),          
          catchError(e => {
            //this.loginPage.loginError = true;
            //this.loginError = true;
            // console.log('GET User Account Failed', e) ;        
            //this.showAlert(e.error.msg); 
            this.logout();         
            throw new Error(e);          
          })          
        );         
  }

  getUserAccountLinkedSuppliersChannel() {
    // console.log('getUserAccountDetails CALLED');
    return this.http.get(`${this.url}/V2/api/useraccountlinkedsupplierschannel/?_ts=${+new Date}`)
      .pipe(                             
          tap(res => {
            this.storage.set('ACCOUNT_LinkedSuppliers', res['DataResult']['LinkedSuppliers']); 
            this.storage.set('ACCOUNT_LinkedSuppliersCount', res['DataResult']['LinkedSuppliersCount']); 
           // this.storage.set('ActiveSupplier', res['DataResult']['UserAccount']['ActiveSupplier']); 
            
            return res['DataResult'];
  
          }),          
          catchError(e => {
            //this.loginPage.loginError = true;
            //this.loginError = true;
            // console.log('GET User Account Failed', e) ;        
            //this.showAlert(e.error.msg); 
            this.logout();         
            throw new Error(e);          
          })          
        );         
  }



}