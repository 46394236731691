import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { ApplicationRef, Component,OnInit } from '@angular/core';
 
import { Platform, NavController, MenuController, ToastController } from '@ionic/angular';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Storage } from '@ionic/storage'

import { Pages } from './interfaces/pages';
import { SwUpdate } from "@angular/service-worker";
import { InteractiveuxService } from "./services/interactiveux.service"
import { ConnectionService } from 'ng-connection-service';
import { interval } from 'rxjs';



 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  public appPages: Array<Pages>; 
  stored: any = {};
  accountUser: any = {};
  isAgent: boolean = false;
  isMultiDataSet: boolean = false;
  activeSupplier: any = {};
  activeSupplierImg: any = {};
  activeSupplierID: any;
  linkedSuppliers: any = {};
  appIsOnline: any;
  appIsOnDevice: any;
  relatedApps: any;
  netConn: any;
  appVisible:any;

  status = 'Online';
  isConnected= true;

  toastHeader: string;
  toastMsg: string;
  toastColour: string;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private authService: AuthenticationService,
    private router: Router,
    public navCtrl: NavController,
    public menuCtrl: MenuController, 
    public update: SwUpdate,
    private appRef: ApplicationRef,
    private storage: Storage,
    public iux: InteractiveuxService,
    private connectionService: ConnectionService,
    public toastController: ToastController
  ) 
  
  {
    console.log('update called');
    update.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      update.activateUpdate().then(() => document.location.reload());
    });
    update.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  
    //  THIS WILL PUSH THE UPDATE ON TOAST
    //this.update.available.subscribe(async res => {
    //   const toast = await this.toastController.create({
    //     message: 'update available!',
    //     position: 'bottom',
    //     buttons: [{ role: 'cancel', text: 'Reload' }]
    //   });
    //   await toast.present();
    //   toast
    //     .onDidDismiss()
    //     .then(() => this.update.activateUpdate())
    //     .then(() => window.location.reload());
    // });
    // this.update.checkForUpdate();
    // setInterval(() => {
    //   this.update.checkForUpdate();
    // } , 15 * 60 * 1000);



    // trying to check the connection status with ng-connection-service working well but have another f way to do it
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = this.isConnected;
    //   if(navigator.onLine){
    //     this.status = "You are Online";
    //   }
    //   else{
    //     this.status = "You are Offline";
    //   }
    //   console.log(this.status);
      
    // }); 
  }
 
  ngOnInit() {

    if (this.update.isEnabled) {
      console.log('update enabled')
      this.update.available.subscribe(() => {
        console.log('update available')
        if (confirm("New version available. Load New Version?")) {
          console.log('trigger reload')
          this.reload();
        }
      });
    }  
      // console.log('ACCOUNT TYPE',accountUserData['DataResult']['UserAccount']['Type']);
      this.accountUser['Type']                  = this.getStoredValue('ACCOUNT_type');
      this.accountUser['LinkedSuppliersCount']  = this.getStoredValue('ACCOUNT_LinkedSuppliersCount');

      if( this.accountUser['Type'] == 10) {
          this.isAgent = true;

      }
      if (this.accountUser['LinkedSuppliersCount'] > 1) {
          this.isMultiDataSet = true;          
      } else {

          this.isMultiDataSet = false;
          this.isAgent        = false;
      }

    //this.events.subscribe('activeSupplier', (activeSupplierData) =>{   

      this.getStoredValue('ActiveSupplier').then(activeSupplierID => {
        this.activeSupplierID = activeSupplierID;
        this.linkedSuppliers = this.getStoredValue('ACCOUNT_LinkedSuppliers');

        for (let si of this.linkedSuppliers) {
            // console.log(si);
            if (si['SupplierID'] === activeSupplierID) {
              this.activeSupplier    = si['SupplierName'];
              this.activeSupplierImg = si['Img'];
              break;
            }
          } 
        
        });
    

    this.initializeApp();

    
  }

  reload() { 
          
    this.update.activateUpdate().then(() => {
        document.location.reload();                             
    })     
    //window.location.reload(true);
      
}
 
  initializeApp() {
    this.platform.ready().then(() => {
  
        this.authService.authenticationState.subscribe(state => {
          if (state) {
            this.attachWindowStatusEvents();
            this.router.navigate(['members', 'selectstore']);
          } else {
            this.router.navigate(['login']);
          }
        });
  
    });
  }

  
  
  async attachWindowStatusEvents() {

    window.addEventListener('offline', async () => {
      this.toastHeader = this.status;
      this.toastMsg    = this.status;
      this.toastColour = 'success';
      console.log('Net Connection OFFLINE')
      this.netConn = false;
    });

    window.addEventListener('connectivitychange', async (ev) => {
      console.log('Net Connection change', ev)
      this.netConn = ev;
    },false);

    window.addEventListener('visibilitychange', async (ev) => {
      console.log('App Visible CHANGE', ev)
      this.appVisible = ev;
    },false);
  }

  async getStoredValue(key: string) {
    await this.storage.get(key).then((val) => {         
          return val;
    }).catch((error) => {
          console.error('ERROR: Storage get for ' + key + ' ', error);          
    });
 }
 /* Updating the app and cheking for update */

    // updateApp(){
    //   if(!this.update.isEnabled){
    //     console.log('NOt Enabled');
    //     return;
    //   }
    //   this.update.available.subscribe((event) =>{
    //     console.log('current', event.current, 'Available', event.available);
    //     if(confirm(' An InStore App update is available! Please confirm to update now.')){
    //       this.update.activateUpdate().then(() => location.reload());
    //     }
    //   }); 

    //   this.update.activated.subscribe((event) => {
    //     console.log('current', event.previous, 'available', event.current);
    //   });

    // }

    // this update the app every 2second to check for update 
    // checkUpdate(){
    //   this.appRef.isStable.subscribe((isStable) => {
    //     if (isStable){
    //       const timeInterval = interval(20000);

    //       timeInterval.subscribe(() => {
    //         this.update.checkForUpdate().then(() => console.log ('cheked'));
    //         console.log('UPdate checked')
          
    //       });
    //     }
    //   })

    // }

  closeSideMenu() {
    //this.menuCtrl.enable(true, 'main');
    this.menuCtrl.close('main');
  }


  logout() {
    this.authService.logout();
  }

  navSelectSupplier() {
    this.navCtrl.navigateRoot('/members/selectsupplier');
  }


}