import { Injectable } from '@angular/core';
import {  AlertController } from '@ionic/angular';
import { HttpClient   } from '@angular/common/http';
import { Observable, of, observable  } from 'rxjs';
import { map, catchError, retry, tap  } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { promise} from 'protractor';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Plugins } from '@capacitor/core';
const { Share } = Plugins;
import { Storage } from '@ionic/storage';
import { resolve } from 'url';
import { ObserveOnOperator } from 'rxjs/internal/operators/observeOn';

// in bytes, compress images larger than 1MB
const fileSizeMax = 1 * 1024 * 1024
// in pixels, compress images have the width or height larger than 1024px
const widthHeightMax = 1024
const defaultWidthHeightRatio = 1
const defaultQualityRatio = 0.7

@Injectable({
  providedIn: 'root'
})


export class GlobalmsgService  {
  
  url = 'https://marketforce.co.za/V2/api/';
  getChildpn:any ={};
  selectedStore: any = {}; 
  store:any ={};

  history: any = {};
 /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(private http: HttpClient,  private authService: AuthenticationService, private storage: Storage, private alertController: AlertController) { }

 
  /**
  * Get data from the Store SEARCH Api
  * map the result to return only the results that we need
  * 
  * @param {string} search Search Term
  * @returns Observable with the search results
  */


 apiQuery(apiaction: string, search: string): Observable<any> {
  return this.http.get(`${this.url}${apiaction}/?search=${encodeURI(search)}&_ts=${+new Date}`).pipe(
    retry(3), 
    map(results => {
        // console.log(results);
        // console.log('HTTP STATUS', results);
        
        return results['DataResult'];
        }
  ));
}

compress(file: File): Observable<File> {
  const imageType = file.type || 'image/jpeg'
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return Observable.create(observer => {
    // This fucking event is triggered each time the reading operation is successfully completed.
    reader.onload = ev => {
      // Create an html image element
      const img = this.createImage(ev)
      // Choose the side (width or height) that longer than the other
      const imgWH = img.width > img.height ? img.width : img.height

      // Determines the ratios to compress the image
      let withHeightRatio = (imgWH > widthHeightMax) ? widthHeightMax/imgWH : defaultWidthHeightRatio
      let qualityRatio = (file.size > fileSizeMax) ? fileSizeMax/file.size : defaultQualityRatio

      // Fires immediately after the browser loads the object
      img.onload = () => { 
        const elem = document.createElement('canvas')
        // resize width, height
        elem.width = img.width * withHeightRatio
        elem.height = img.height * withHeightRatio

        const ctx = <CanvasRenderingContext2D>elem.getContext('2d')
        ctx.drawImage(img, 0, 0, elem.width, elem.height)
        ctx.canvas.toBlob(
          // callback, called when blob created
          blob => { 
            observer.next(new File(
              [blob],
              file.name,
              {
                type: imageType,
                lastModified: Date.now(),
              }
            ))
          },
          imageType,
          qualityRatio, // reduce image quantity 
        )
      }
    }

    // Catch errors when reading file
    reader.onerror = error => observer.error(error)
  })
}
private createImage(ev) {
  let imageContent = ev.target.result
  const img = new Image()
  img.src = imageContent
  return img
}


async getStorageValue(key: string) {
  return await this.storage.get(key).then((val) => {
        console.log('Storage get: ' + key + ' ', val);            
        this.selectedStore[key] = "";
        this.selectedStore[key] = val;
        return this.selectedStore[key];
  }).catch((error) => {
        console.log('ERROR: Storage get for ' + key + ' ', error);
  });
}

async shareOn(photonote){
  await this.getStorageValue('STORE_NAME');
  await this.getStorageValue('STORE_CHANNEL');
  let 
  store=this.selectedStore['STORE_CHANNEL'] + " " + this.selectedStore['STORE_NAME'],
  
  pnSku = photonote[0].pnData.articleDescription,
  pnFlag = photonote[0].pnData.flagDescription,
  pnNote = photonote[0].pnData.note,
  pnCreator = photonote[0].pnData.userName
  let pnImgs = []
  if (photonote[0].pnfiles != null){
    for  (let i = 0; i < photonote[0].pnfiles.length; i++){
  pnImgs.push('\n'+" " + encodeURI(photonote[0].pnfiles[i].filePath) + " ")
    }
}
else {
  pnImgs.push('None')
}
if (store.toUpperCase() == photonote[0].pnData.articleDescription){
  pnSku = "None"
}
let  title = 'PHOTONOTE by MARKETFORCE'; // this work only for email 
 
   const pnShare = {
    // url:pnImgs,
      title: title,
      text: 
      title+
     '\n'+'\n'+'Store: '+store+
      '\n'+'SKU: '+ pnSku +
      '\n'+'Flag: '+ pnFlag +
      '\n'+'Note: '+ pnNote +
       '\n'+'Creator: '+ pnCreator +
       '\n'+'Photo(s): '+ pnImgs,
      // files:pnImgs,
   }
   // console.log('SHared details', pnShare);
   if (navigator.share) {
     navigator.share(pnShare)
     .then(() => console.log('photonote Sharing was successful.'))
     .catch((error) => console.log('photonote Sharing failed', error));
   } else {
     console.log(`Your system doesn't support photonote Sharing.`);
   }
 }

}
