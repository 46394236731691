import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiQueryService } from 'src/app/services/apiquery.service';
import { GlobalmsgService } from 'src/app/services/globalmsg.service';
import { isArray } from 'util';

@Component({
  selector: 'app-photoview',
  templateUrl: './photoview.page.html',
  styleUrls: ['./photoview.page.scss'],
})
export class PhotoviewPage implements OnInit {

  activeNote: any=[];
  linkedNotes: any = [];
  img: any = [];
  stored: any = {};
  activeSupplierName: Promise<any>;
  noResults: boolean = false;
  ArticleNumber: any;
  selectedStore: any = {};  
  skuInfo: any = [];  
  showSpinner: boolean = true;
  timerInitChart: any;
  isLoading: boolean = false;
  live: boolean = false;

  
  sliderConfig = {
    slidesPerView: 1.05,
    spaceBetween: 0,
    centeredSlides: true
  };

    /// CHART SETUP
    echartsIntance: any;
    chartdatas: any = null;
    chartoptions = {
      scales: {
        yAxes: [{
            ticks: {
                fontSize: 10,
            }
        }]
    },
      title: {
        show: false,
        text: 'Sales out 7 days'
      },
      grid: {
        top:    15,
        bottom: 30,
        left:   '10%',
        right:  '10%',
      },
      animation: true,
      color: ['#3398DB'],
      tooltip : {
          trigger: 'axis',
          axisPointer : {            
              type : 'shadow'        
          }
      },
  
      xAxis : [
          {
              type : 'category',
              data : [],
              axisTick: {
                alignWithLabel: true
              }
          }
      ],
      yAxis : {},
          series : [{
            name: 'Volume Sales',
            type:'bar',
            barWidth: '50%',
            data:[]
        }]
          
      };
  loadLength: any = 0;
  allLoaded: boolean = false;
  allLinkedNotes: any = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private storage: Storage,
    private apiqueryService: ApiQueryService,
    private globalmsgService: GlobalmsgService,
    private router: Router,
  ) {
    
   }

 async ngOnInit() {

  

   await this.storage.get('activeNote').then((note) => {
      console.log(note);
      this.activeNote.push(note);
    });
    let pnID = "pnID=" + this.activeNote[0]['pnData']['pnID']
  
    
    let read ='read=1'

this.apiqueryService.updateLastSeen(pnID, read)
console.log(pnID, read)

    await this.storage.get('linkedNotes').then((linkedNotes) => {
      console.log(linkedNotes);
      this.allLinkedNotes = linkedNotes
      this.loadMore()
      
    });
    this.getSkuData()
    if (this.activeNote[0]['pnfiles'] == null){
      this.toNotes()
      this.img.push("/assets/imgs/logo.png")
    }
   else {
      for (let i = 0; i < this.activeNote[0]['pnfiles'].length; i++){
        this.img.push(this.activeNote[0]['pnfiles'][i]['filePath'])
      }
      this.toPhotos()
      
     
   console.log(this.img)
    }
    

  }

  async getStoreParams() {
    await this.getStorageValue('STORE_ID');
    await this.getStorageValue('STORE_NAME');
    await this.getStorageValue('STORE_CHANNEL'); 
    
                   
 }

 share(photoNote){
  this.globalmsgService.shareOn(photoNote)
}

 async getStorageValue(key: string) {
  return await this.storage.get(key).then((val) => {
        // console.log('Storage get: ' + key + ' ', val);
        this.stored[key] = '';
        this.stored[key] = val;
        return this.stored[key];
  }).catch((error) => {
        console.error(' getStorageValue Storage get for ' + key + ' ', error);
  });
}

      async getSkuData() {

    this.isLoading = true;
   
  
    await this.getStoreParams();
    console.log('CALLING API');
    console.log(this.stored['STORE_ID'], this.stored['STORE_CHANNEL'],this.activeNote[0]['pnData']['skuID'])
    //return this.getStoreListingInfo(this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL']).then()
await this.apiqueryService.getSkuInfo(this.stored['STORE_ID'], this.stored['STORE_CHANNEL'],this.activeNote[0]['pnData']['skuID']).then((result) => {
        this.skuInfo = result;
    console.log(this.skuInfo)
              var d:any;             

              for(d in this.skuInfo.SDC) {
                    console.debug('SKUINFO TESTING VALUES: ',this.skuInfo.SDC[d]) 
                    if(typeof this.skuInfo.SDC[d] === 'undefined' || this.skuInfo.SDC[d] === null || this.skuInfo.SDC[d].length == 0) {
                      console.debug('SKUINFODATA ELEMENT IS undef,null or not set', this.skuInfo.SDC[d]);
                      this.skuInfo.SDC[d] = 'Not Available';
                    }       
              }
             
              console.log('API SKUINFO DONE', result);
              console.log('SKUINFO ARRAY', this.skuInfo);             
          }).then((result) => {

            this.isLoading = false;
            return result;

          });
          
    //  })
    
  }

  async toPhotoNote(articleNumber: number, articleDescription: string){

    this.storage.set('linkedArticleNumber', articleNumber[0]);
    this.storage.set('linkedArticleDetails', articleDescription[0]+'\n['+articleNumber[0]+']');
    this.storage.set('skuPhoto', true)
    this.storage.set('taskPN', false)
    if (this.activeNote[0]['pnData']['linkedID'] == null || this.activeNote[0]['pnData']['linkedID'] == 0){
    this.storage.set('linkedID', this.activeNote[0]['pnData']['pnID']);
    }
    else {
      this.storage.set('linkedID', this.activeNote[0]['pnData']['linkedID']);
    }
    this.storage.set('linkedPN', true)
    await this.router.navigate(['/members/photonote']);
      }


  toNotes(){
    let schedul = document.getElementById("schedulContent");
    schedul.style.display = "inline"

    let dashboard = document.getElementById("dashboarContent");
    dashboard.style.display = "none"

    let dashBtn = document.getElementById("dashboarBtn");
    dashBtn.style.color = "white"
    dashBtn.style.backgroundColor = "#018ee4"

    let schedBtn = document.getElementById("schedulBtn");
    schedBtn.style.color = "black"
    schedBtn.style.backgroundColor = "white"
  }

  toPhotos(){
    let schedul = document.getElementById("schedulContent");
    schedul.style.display = "none"

    let dashboard = document.getElementById("dashboarContent");
    dashboard.style.display = "inline"

    let dashBtn = document.getElementById("dashboarBtn");
    dashBtn.style.color = "black"
    dashBtn.style.backgroundColor = "white"

    let schedBtn = document.getElementById("schedulBtn");
    schedBtn.style.color = "white"
    schedBtn.style.backgroundColor = "#018ee4"
  }
  @ViewChild(IonSlides) slides: IonSlides;
  async zoom(zoomIn: boolean){
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  async zoomimg(note){

      note['pnData']['lastSeen'] = 'read'

      await this.storage.set('activeNote', note)
      this.activeNote =[];
      this.linkedNotes = [];
      this.img =[];
       this.ngOnInit();

    }


  onChartInit(ec: any) {
    console.log('INIT ECHARTS INSTANCE');
    this.echartsIntance = ec;
     this.timerInitChart = setInterval(() => { this.updateChart(); }, 500);    
  
  } 
  
  dateChange(date){
   if (date == 0){
     this.live = false
   }
   if (date == 1){
     this.live = true
   }
  }
  
  updateChart() {
      console.log('UPDATE CHART - ARRAY CHECK',this.skuInfo);
      if(isArray(this.skuInfo['CHART']['DOW'])) {
          clearInterval(this.timerInitChart);
      
          console.log('STARTING CHART UPDATE');
              this.echartsIntance.setOption({
                    xAxis: {
                        data: this.skuInfo.CHART.DOW
                    },
                    series : [{
                          name: 'Unit Sales',
                          type:'bar',
                          barWidth: '50%',
                          data: this.skuInfo.CHART.Units
                    }]
                  })
  
              console.log('FINISH CHART UPDATE');
              
      } else {
        console.log('UPDATE CHART - Requirements NOT Set, Looping');
      }
  
  }

  loadMore(){

    this.loadLength = this.loadLength + 5;
    if (this.loadLength >= this.allLinkedNotes.length ){
      this.loadLength = this.allLinkedNotes.length;
      this.allLoaded = true;
    }
    this.linkedNotes = this.allLinkedNotes.slice(0, this.loadLength)
    if (this.allLinkedNotes.length == 0){
      this.noResults = true;
    }
    else {this.noResults= false}
  }

}
