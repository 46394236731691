import { Platform, AlertController } from '@ionic/angular';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
 

 
@Injectable({
  providedIn: 'root'
})
export class StoreLocationLookupService {

  url = 'https://marketforce.co.za/V2/api/storelocation/';

  data: any = {};
  
 
  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(private http: HttpClient, private authService: AuthenticationService, private alertController: AlertController) { }  


 
  searchData(lat: number, lng: number, acc: number, id:string): Observable<any> {
  this.data = this.http.get(`${this.url}?lat=${lat}&lng=${lng}&acc=${acc}&id=${id}&_ts=${+new Date}`).pipe(
    retry(0), 
        map(results => {
            console.log('API GET RESULTS', results)
            return results['GeoStores'];         
            //return results;
        }),
        catchError(err => {
          console.log('Location Search ERROR CODE', err);
          console.log('Location Search CODE STATUS', err.status);
          // this.authService.logout(); 
          // this.showAlert('GPS Location Lookup not available. Please Manually search for your store.','Location Error'); 
          return of([]);
        })
    );
  return this.data;
 }


 // Using IP as seen by SERVER, get suburb/province/City etc. use that a GPS, but set Range to unrealistically wide.
 approximateLocation(): Observable<any> {

    this.data = this.http.get(`https://marketforce.co.za/V2/api/approximateGPS/?_ts=${+new Date}`).pipe(
      retry(0), 
          map(results => {
              console.debug('API approximateGPS GET RESULTS', results)
              return results;         
              //return results;
          }),
          catchError(err => {
            console.log('cant get approximate location')
            return of([]);
            
          })
      );
    return this.data;
 }

 showAlert(msg, hdr) {
  let alert = this.alertController.create({
    message: msg,
    header: hdr,
    buttons: ['OK']
  });
  alert.then(alert => alert.present());
 }
 
}