import { ChangeDetectorRef, Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit} from '@angular/core';

import { Observable } from 'rxjs';
import { PopoverController, ModalController } from '@ionic/angular';
import { ApiQueryService } from './../../services/apiquery.service';
import { Storage } from '@ionic/storage';
import { Router, NavigationExtras } from '@angular/router';
import Quagga from 'quagga';




@Component({
  selector: 'app-scansku',
  templateUrl: './scansku.page.html',
  styleUrls: ['./scansku.page.scss']
  
})



export class ScanskuPage implements AfterViewInit {

  @ViewChild('fileInput',{static: false}) imgFileInput: ElementRef;
  @Input() returnTo: any;

  

  showScanner: boolean = true;
  showSearchBar: boolean = false;
  scannerIsRunning: boolean = false;
  isSearching: boolean = false;
  noResults: boolean;
  setState: any = {};
  debugMedia: any;
  debugMediaGetUserMedia: any;
  barcode: any;
  videoError: boolean = false;
  bcError: any = null;
  lastScanResult: any = null;

  debugOut: any = '';
  viewScanPhoto: any = '';
  isLoading: boolean = false;

  public imagePath;
 
  isModal: boolean = false;
  title: any = 'Scan or Search SKU';

  

  apiAction: any;
  results: Observable<any>;   
  searchResults: any = [];
  searchNotListed: any = [];
  searchListed: any = [];
  
  searchTerm: string = '';
  //searchChannel: SearchChannel = SearchChannel.all;
  url: any = 'https://marketforce.co.za/api/';
  selectedStore: any = {};
  
  customPopoverOptions: any = {   
    animated: true,
    translucent: true    
  };
  scan: boolean = false;
  all: string = "a"



  
  constructor(
    private ref: ChangeDetectorRef,
    private apiqueryService: ApiQueryService,
    public popoverController: PopoverController,
    private storage: Storage,
    private router: Router,
    private modalCtrl: ModalController
    
    ) {  }

  /*ngOnInit() {
    this.getStoreParams();

    // camera chekz
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      
      console.log('MediaDevices Supported',navigator.mediaDevices);
      console.log('MediaDevices Supported - getUserMedia',navigator.mediaDevices);
    }

    
  }*/


  async ngAfterViewInit() {


    if(this.returnTo == 'photoNote') {
      this.isModal = true;
      this.title   = "Link SKU";
      console.log('IsMODAL is TRUE');
    } else {
      this.title = 'Scan or Search SKU';
    }  
    



await  this.getStoreParams();

await this.delay(500)
console.log(this.selectedStore['STORE_ID'])
await this.apiqueryService.getStoreListingInfo(this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL'],'0').subscribe(result => {
  result;       
  console.log(result);
})

if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

console.log('MediaDevices Supported',navigator.mediaDevices);
console.log('MediaDevices Supported - getUserMedia',navigator.mediaDevices);
}

this.getSku(this.all)

}

delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

async getSku(search){
  await this.delay(500)
   this.scan = false;
   this.isLoading = true;

   console.log('Search Changed (search)', search);
   console.log('Search Changed (this.searchTerm)', this.searchTerm);

   console.log('APIQUERY FUNC VIA SEARCH BAR DATA:'+this.searchTerm);
   this.showScanner = false;        
   this.stopScanner();
   this.apiAction = 'searchSKU';


         this.isSearching = true;
         console.log(this.selectedStore)
         this.apiqueryService.searchSKU(this.apiAction, this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL'], this.all).subscribe(result => {

           if(result === null) {
             console.log('SEARCH API - Resultset is 0', result);
             this.noResults = true; 
             //this.loadingController.dismiss();     
           } else {
             console.log('SEARCH API - Has Results', result);
             this.noResults = false;
             this.searchListed = result['Listed'];
             this.searchNotListed = result['NotListed'];   

           } 
           
           this.isLoading = false;
           console.log('HTTP RESULTS:'+JSON.stringify(this.searchResults));

           /**
            * article_number as articleNumber, 
                               s.barcode as barcode, 
                               article_description as articleDescription,
                               s.vendor_product_code as vendorProductCode, 
                               week_ending_date as dataDate, 
                               site_article_status as articleStatusCode, 
                               soh_qty as sohQTY, 
                               dros_qty as drosQTY, 
                               source_of_supply as supplySource, 
                               date_last_sold as dateLastSold, 
                               date_last_ordered as dateLastOrdered, 
                               date_last_received as dateLastReceived
            */

         });
   
 }

  async toPhotoNote(articleBarcode: number){

    console.log(articleBarcode[0])
    this.storage.set('linkedArticleNumber', 'UNREGISTERED');
    this.storage.set('linkedArticleDetails', 'UNREGISTERED');
    this.storage.set('skuFlag', true)
    this.storage.set('taskPN', false)
    this.storage.set('linkedArticleBarcode', articleBarcode[0])
    await this.router.navigate(['/members/photonote']);
      }

  clearSearch(){
    this.getSku(this.all)
  }

      @ViewChild('search') search : any;
  toggleSearchBar() {
    console.log('Show/Hide Search Bar');
    if(this.showSearchBar === true) {
        this.showSearchBar = false;
    } else {
      this.showSearchBar = true;
    }
    this.focus()
  }

  ionViewWillLeave() {
    console.log('IONCI VIEW WILL LEAVE');
    console.log('CALLING STOP SCANNER - func ionViewWillLeave');
    this.stopScanner();
  }

  getStoreParams() {
    this.getStorageValue('STORE_ID');
    this.getStorageValue('STORE_NAME');
    this.getStorageValue('STORE_CHANNEL');
    this.getStorageValue('STORE_BANNER');
    this.getStorageValue('SELECT_GEO_LAT');
    this.getStorageValue('SELECT_GEO_LON');
    this.getStorageValue('SELECT_GEO_ACC');    
                     
  }

  getStorageValue(key: string) {
      this.storage.get(key).then((val) => {
            console.log('Storage get: ' + key + ' ', val);            
            this.selectedStore[key] = "";
            this.selectedStore[key] = val;
      }).catch((error) => {
            console.log('ERROR: Storage get for ' + key + ' ', error);
      });
  }

  focus(): void {
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }

   searchChanged(search) {

        this.isLoading = true;
        this.scan = false;
        console.log('Search Changed (search)', search);
        console.log('Search Changed (this.searchTerm)', this.searchTerm);
 
        //console.log('APIQUERY FUNC VIA SEARCH BAR DATA:'+this.searchTerm);
        this.showScanner = false;        
        this.stopScanner();
        this.apiAction = 'searchSKU';

        if(this.searchTerm.length === 0) {
          console.log('SEARCH - PRE API - length is 0. Hide NoResult, set Resultset to null');
          this.noResults = null; 
          this.searchResults = null;
          this.isLoading = false;
          // this.isSearching = false;
        } else {
              this.isSearching = true;
              this.apiqueryService.searchSKU(this.apiAction, this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL'], this.searchTerm).subscribe(result => {

                if(result === null) {
                  console.log('SEARCH API - Resultset is 0', result);
                  this.noResults = true; 
                  //this.loadingController.dismiss();     
                } else {
                  console.log('SEARCH API - Has Results', result);
                  this.noResults = false;
                  this.searchListed = result['Listed'];
                  this.searchNotListed = result['NotListed'];   

                } 
                
                this.isLoading = false;
                //console.log('HTTP RESULTS:'+JSON.stringify(this.searchResults));

                /**
                 * article_number as articleNumber, 
                                    s.barcode as barcode, 
                                    article_description as articleDescription,
                                    s.vendor_product_code as vendorProductCode, 
                                    week_ending_date as dataDate, 
                                    site_article_status as articleStatusCode, 
                                    soh_qty as sohQTY, 
                                    dros_qty as drosQTY, 
                                    source_of_supply as supplySource, 
                                    date_last_sold as dateLastSold, 
                                    date_last_ordered as dateLastOrdered, 
                                    date_last_received as dateLastReceived
                 */

              });
        }
      }

    /*
     searchBarcode(search) {      

      console.log('searchBARCODE searchTerm: ',this.searchTerm);
      console.log('searchBARCODE search param: ',search);
      this.showScanner = false;
      console.log('CALLING STOP SCANNER - func searchBarcode');
      this.stopScanner();
      this.apiAction = 'searchSKU';       
      
          this.apiqueryService.searchSKU(this.apiAction, this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL'], search).subscribe(result => {

                console.log('BARCODE SEARCH API - Has Result', result);
                if(result == null) {
                  this.noResults = true;
                } else {
                  this.noResults = false;
                  this.searchResults = result;
                  this.router.navigateByUrl('/members/skuinfo/'+result[0]['PNP_article_number']);
                }
              
                                  

            });
      }
      */

     searchBarcode(search) {      
      this.lastScanResult = null;
      this.isLoading = true;
      console.log('searchBARCODE searchTerm: ',this.searchTerm);
      console.log('searchBARCODE search param: ',search);
      this.showScanner = false;
      this.isSearching = true;
      console.log('CALLING STOP SCANNER - func searchBarcode');
      this.stopScanner();
      this.apiAction = 'searchSKU';       
      
          this.apiqueryService.searchSKU(this.apiAction, this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL'], search).subscribe(result => {

                console.log('BARCODE SEARCH API - Has Result', result);
                if(result == 'null' || result === null ) {
                  this.noResults = true;
                  this.isLoading = false;
                } else {
                  if(this.isModal) {

                    this.searchTerm = '';
                    this.barcode = ''; 
                    this.noResults = false; 
                    this.isSearching = false;                 
                    this.ref.detectChanges();
                    this.isLoading = false;

                    this.selectSKUModal(result['Listed'][0]['ArticleNumber'], result['Listed'][0]['ArticleDescription'], result['Listed'][0]['Barcode']);

                  } else {

                    this.router.navigateByUrl('/members/skuinfo/'+result['Listed'][0]['ArticleNumber']);
                    this.searchTerm = '';
                    this.barcode = '';                 
                    this.searchChanged(this.searchTerm);                  
                    this.noResults = false; 
                    this.isSearching = false;                 
                    this.ref.detectChanges();
                    this.isLoading = false;

                  }
                  
                }
              
                                  

            });
      }
        
  //*************************** CAMERA / SCANNER DEV AND TESTING ************************************** */
  // camera options (Size and location).The preview uses the rear camera and display the preview in the back of the webview

  startScanner() {
    this.showScanner = true;
    this.searchResults = null;
    this.noResults = false;
    this.barcode = '';
    this.scannerIsRunning = true;
    this.ref.detectChanges();

   
    
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

              Quagga.init({
                inputStream : {
                  name : "Live",
                  type : "LiveStream",
                  target: document.querySelector('#video')
                },
                constraints: {
                  width: 1280,
                  height: 720,
                  facing: "environment"
              },
              locator: {
                patchSize: "small",
                halfSample: true
              },
                locate: true,
                decoder : {
                  readers : ["ean_reader", "upc_reader"]
                },              
              },(err) => {
                if (err) {                 
                    this.videoError = true;                 
                    return;
                }
                //this.onInitSuccess();
                console.log('Barcode: initialization finished. Ready to start');
                Quagga.start();

            });
            //Quagga.onDetected(this.onDetected);
            //Quagga.onProcessed((result) => this.onProcessed(result)); 
            Quagga.onProcessed((result) => this.logCode(result));
            
    }

    
    // Quagga.onDetected((result) => this.logCode(result));    
    // Quagga.onDetected(this.onDetected);
    // Quagga.start();
    
          
};


stopScanner() {
  console.log('func STOP SCANNER');
  console.log('var scannerIsRunning',this.scannerIsRunning);
  if(this.scannerIsRunning === true) {
    console.log('calling Quagga.stop - temp disbaled for testing' );
    Quagga.stop();
  }
    this.showScanner = false;
    this.scannerIsRunning = false;
    this.ref.detectChanges();
    
} 

onProcessed(result: any) { 
  var drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;

  if (result) {
      if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(function (box) {
              return box !== result.box;
          }).forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
          });
      }
                                                  
      if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
      }
        
      if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
      }
  }
};

logCode(result) {
  console.log('LOGCODE Called', result);
  if (result != undefined){
    if (result.codeResult != undefined){
    const code = result.codeResult.code;
    this.scan = true;
    this.stopScanner();
    //const code = '04000539361807';
    console.log('BARCODE DETECTED', code);
   // if (this.barcode !== code) {
   // this.searchChanged(code);
   
    this.searchBarcode(code);
   
    this.lastScanResult = code;
    this.barcode = ''; 
    
    console.log('CALLING STOP SCANNER - func logCode ');
    

    return code;
 
      //return code;     
   // }
    }}
 }

  // Tests for Capture from "image/Camera Photo"
  scanPhoto(files){

     // Reset All Status, clear any results.
     // this.stopScanner();
     console.log('FILES',files);
     this.debugOut = this.debugOut +'| scanPhoto |';

     this.searchResults = null;
     this.noResults = false;
     this.barcode = null;
     this.searchTerm = '';
     

      
      var reader = new FileReader(); 
      reader.readAsDataURL(files[0]);
      reader.onload =  (_event) => {
        
            var imgDataURL = reader.result; 
            this.debugOut = this.debugOut +'| imgDataURL: '+imgDataURL+' |';            

            console.log('image dataURL', imgDataURL);
            this.viewScanPhoto = imgDataURL;

            Quagga.decodeSingle({       
                    inputStream: {
                      name: "Photo",
                      type : "ImageStream",           
                      singleChannel: false
                    },                   
                    decoder: {
                        readers: ["ean_reader", "upc_reader"] // List of active readers
                    },                
                    src: imgDataURL
                  }, (result) => {
                                    //let bc = this.logCode(result);
                                    this.debugOut = this.debugOut +'| BC DECODE RESULT: '+result.codeResult.code+' |';
                                    let bc = result.codeResult.code;
                                    //let bc = '0400053936180'; // TEMP
                                    this.imgFileInput.nativeElement.value = "";
                                    this.searchTerm = bc;                           
                                    //this.searchChanged(bc);
                                    this.searchBarcode(bc);
                                    this.barcode = '';
                                    this.searchResults = null;
                                          
                                } 
                );

        }

    }


    async closeModal() {
      // CANCEL      
      await this.modalCtrl.dismiss(null);
    }

    async selectSKUModal(articleNumber, articleDescription, articleBarcode) {
      //const onClosedData: string = id;
      await this.modalCtrl.dismiss({articleNumber: articleNumber, articleDescription: articleDescription, articleBarcode: articleBarcode});
    }

   

}