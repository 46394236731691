<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button
        slot="”icon-only”"
        defaultHref="/members/dashboard"
      ></ion-back-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button slot="”icon-only”" (click)="toggleSearchBar()">
        <ion-icon
          name="arrow-dropup"
          class="text06"
          *ngIf="showSearchBar"
        ></ion-icon>
        <ion-icon name="search"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>
      <ion-text color="light">Surveys</ion-text>
    </ion-title>
  </ion-toolbar>

  <div *ngIf="showSearchBar">
    <ion-toolbar color="logo" class="ion-no-padding">
      <ion-searchbar
        #search
        [(ngModel)]="searchTerm"
        (ionChange)="setFilteredItems()"
        debounce="800"
        inputmode="search"
        mode="md"
        animated
        clear-icon
      ></ion-searchbar>
    </ion-toolbar>
  </div>

  <!-- <ion-segment  mode="ios"  class="text08" value="{{state}}" (ionChange)="setTaskType($event.target.value)" id="taskSegment">
    <ion-segment-button mode="ios" name="assigned" value="assigned"  checked>           
      <ion-label class="text08" >ASSIGNED ({{assignedCount}})</ion-label>
    </ion-segment-button>
    <ion-segment-button mode="ios" name="available" value="available">            
      <ion-label class="text08">AVAILABLE ({{availableTasks.length}})</ion-label>
    </ion-segment-button>
    <ion-segment-button mode="ios" name="complete" value="complete">            
      <ion-label class="text08">COMPLETE ({{pnArray.length}})</ion-label>
    </ion-segment-button>
</ion-segment> -->

</ion-header>

<ion-content id="dashboardContent">
  <ion-progress-bar type="indeterminate" *ngIf="isLoading"></ion-progress-bar>

  <ion-refresher name="refresher" slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div *ngIf="activeTasks != undefined">

    <ion-card class="center text12 margin10  light padding5 " *ngIf="activeTasks.length === 0">
      <ion-card-title class=" text15  light padding5 ">No active tasks</ion-card-title>
     
    </ion-card>

<ion-card class="center text12 margin10  light padding5 " *ngFor="let task of activeTasks; let i = index">
  <ion-card-title class=" text15  light padding5 ">{{task.taskName}}</ion-card-title>
{{task.taskDescription}}
<ion-row>
  <ion-col class="center" size="4">
    <ion-icon name="time-outline"></ion-icon>
    {{task.taskEta}}
  </ion-col>
  <ion-col class="center" size="4">
    <ion-icon name="checkbox-outline"></ion-icon>
    {{task.taskProgress}}/{{task.taskTotal}}
  </ion-col>
  <ion-col class="center" size="4">
    <ion-icon name="calendar-outline"></ion-icon>
    {{task.taskExpiration}}
  </ion-col>
</ion-row>
<ion-button *ngIf="task.taskProgress === '0'" class="margin10 modal-button" (click)="toTaskView(task.taskID)" expand="block" fill="solid" size="default" [color]="'primary'"  routerDirection="forward" >
  <ion-text>START</ion-text>
  <ion-icon  slot="end" name="enter-outline" class="storecheckinicon icon24" ></ion-icon>
</ion-button> 
<ion-button *ngIf="task.taskProgress != '0'" (click)="toTaskView(task.taskID)" class="margin10 modal-button" expand="block" fill="solid" size="default" [color]="'primary'"  routerDirection="forward" >
  <ion-text>CONTINUE</ion-text>
  <ion-icon  slot="end" name="enter-outline" class="storecheckinicon icon24" ></ion-icon>
</ion-button>
</ion-card>
</div>
 
</ion-content>



