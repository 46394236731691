<ion-header >
    
  <ion-toolbar color="primary">  
    <ion-buttons slot="start">
        <ion-back-button slot=”icon-only”  defaultHref="/members/gallery"></ion-back-button>
    </ion-buttons>

    <ion-title *ngFor="let n of activeNote">       
          <ion-text color="light">PhotoView</ion-text>       
    </ion-title>
  </ion-toolbar>  
  <ion-row class="ion-no-margin ion-no-padding">
    <ion-col class="ion-no-margin ion-no-padding">
  <ion-buttons (click)="toPhotos()" id="dashboarBtn" class="center ion-no-padding ion-text-center switchButton" >


   <p class="btn ion-no-padding">  Photos</p>
 
  </ion-buttons>
</ion-col>
<ion-col class="ion-no-margin ion-no-padding">
  <ion-buttons (click)="toNotes()" id="schedulBtn" class="center ion-text-center ion-no-padding switchButton" >

  
     <p class="btn ion-no-padding"> Notes</p>
  
  </ion-buttons>
</ion-col>
</ion-row>
</ion-header>
<ion-content  id="dashboarContent" class="ion-no-padding">

  
  <div *ngFor="let note of activeNote">
    <div class="ion-padding">
        <div class="fw500">{{activeNote[0]["pnData"]["articleDescription"]}} </div>
      
        <div><ion-badge class="text07">{{activeNote[0]["pnData"]["loadedTS"]}}</ion-badge></div>
    </div>
  </div>
  <ion-slides pager="true" class="previmg ion-padding-bottom">
 <!-- [options]="Pimgs" -->
   <ion-slide *ngFor="let image of img;let i = index">
    <div class="swiper-zoom-container ion-margin-bottom">
      <img src="http://imgsvr01.marketforce.co.za/300x,jpg/{{img[i]}}"   />    
</div>
</ion-slide>                    
 
</ion-slides>

</ion-content>

<ion-content  id="schedulContent" >
  <ion-progress-bar type="indeterminate" *ngIf="isLoading"></ion-progress-bar>

   <div *ngFor="let note of activeNote">
  <div class="ion-padding">
      <div class="fw500">{{activeNote[0]["pnData"]["articleDescription"]}} </div>
    
      <div><ion-badge class="text07">{{activeNote[0]["pnData"]["loadedTS"]}}</ion-badge></div>
  </div>

  <ion-item >                                
    <ion-icon name="person-outline" slot="start"></ion-icon>
    <ion-label><span class="ion-float-left">Creator</span><span class="ion-float-right">{{activeNote[0]["pnData"]["userName"]}}</span></ion-label>               
</ion-item> 

<ion-item *ngIf="(activeNote[0]['pnData']['flagDescription']) != null">                                
  <ion-icon name="flag-outline" slot="start"></ion-icon>
  <ion-label><span class="ion-float-left">Flag Type</span><span class="ion-float-right">{{activeNote[0]["pnData"]["flagDescription"]}}</span></ion-label>               
</ion-item> 

  <ion-item>                                
    <ion-icon name="clipboard-outline" slot="start"></ion-icon>
  <ion-text class="text1">Note:</ion-text> 
  </ion-item> 
 

  <ion-item class="textArea ion-align-items-center">   
    <ion-icon slot="start"></ion-icon>                             
    <textarea readonly class="textArea ">{{activeNote[0]["pnData"]["note"]}}</textarea> 
    </ion-item> 


    <!-- RESPONSE PHOTONOTES: -->
    <div *ngIf="(activeNote[0]['pnData']['linkedID']) == null || (activeNote[0]['pnData']['linkedID']) == 0">
    <ion-item color="logo">
       <!-- <ion-icon class="icon24 ion-no-margin" slot="start" name="camera-outline"></ion-icon> -->
      <ion-label><span class="ion-float-left">RESPONSE PHOTONOTES ({{allLinkedNotes.length}})</span></ion-label>
      <ion-icon class="icon24 ion-no-margin" slot="end" name="add-circle-outline" (click)="toPhotoNote([activeNote[0]['pnData']['skuID']], [activeNote[0]['pnData']['articleDescription']])"></ion-icon>
       </ion-item>
    
      
        <ion-card *ngIf="noResults">
          <ion-card-header class="ion-no-padding">
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label
                    class="fw600 ion-text-wrap"
                    color="dark"
                    style="display: block;"
                  >
                    No Responses
                  </ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-header>
        </ion-card>
     
        

       <ion-slides *ngIf="!noResults" [options]="sliderConfig" class="ion-padding-bottom" >
        
        <ion-slide  *ngFor="let item of (linkedNotes); let i=index" >

       
            <div
              class="xgCard"
              [ngStyle]="{'background-color':(this.linkedNotes[i]['pnData']['lastSeen']) == 'read'? 'white' : 'rgb(199, 241, 201)' }"
    
            >
              <div class="imgCont ion-margin-left">
                <img
                  (click)="zoomimg(linkedNotes[i])"
                  *ngIf="(this.linkedNotes[i]['pnfiles'])"
                  src="{{linkedNotes[i]['pnfiles'][0]['filePath']}}"
                />
                <img
                  (click)="zoomimg(linkedNotes[i])"
                  *ngIf="(this.linkedNotes[i]['pnfiles']) == null"
                  src="/assets/imgs/logo.png"
                />
              </div>
              <div class="xgContent">
                <div
                  *ngIf="(linkedNotes[i]['pnData']['articleDescription'])"
                  (click)="zoomimg(linkedNotes[i])"
                  class="Btitle text12"
                >
                {{linkedNotes[i]['pnData']['userName']}}
                  <br>
                  <ion-badge class="text09" *ngIf="(this.linkedNotes[i]['pnData']['flagType']) != null" [ngStyle]="{'background-color':(this.linkedNotes[i]['pnData']['flagType']) == '0' ? '#018ee4;' : 'rgb(250, 75, 75)' }" >{{linkedNotes[i]['pnData']['flagDescription']}}</ion-badge>
              
                </div>
    
                <div (click)="zoomimg(linkedNotes[i])" class="not">
                  {{linkedNotes[i]['pnData']['note']}}
                </div>
              
                <span (click)="zoomimg(linkedNotes[i])" class="authnm">
                  {{linkedNotes[i]['pnData']['loadedTS']}}
                
                </span>
              </div>
              <div class="xgIcon ion-margin-left ion-margin-right">
                <span (click)="zoomimg(linkedNotes[i])" class="chatico">
                
                  <ion-icon name="chatbubbles-outline"></ion-icon>
                </span>
                <span (click)="zoomimg(linkedNotes[i])" class="imgico">
                  <ion-badge
                    *ngIf="(this.linkedNotes[i]['pnfiles'])"
                    class=""
                    color="primary"
                  >
                    {{this.linkedNotes[i]['pnfiles'].length}}
                  </ion-badge>
                  <ion-icon name="images-outline"></ion-icon>
                </span>
                <span>
                  <ion-icon
                    class="shrico"
                    (click)="share([linkedNotes[i]])"
                    name="share-social-outline"
                  ></ion-icon>
                </span>
              </div>
            </div>
     
     

          <!-- OLD CARD -->
            <!-- <ion-item class="Lstitm" *ngIf="(linkedNotes[i]['pnData']['skuID']) != 'UNREGISTERED'">
                <div class="lftcnt">
              
                  <img  (click)="zoomimg(linkedNotes[i])" *ngIf="(this.linkedNotes[i]['pnfiles'])" src="{{activeNote[i]['pnfiles'][0]['filePath']}}" />
                  <img  (click)="zoomimg(linkedNotes[i])" *ngIf="(this.linkedNotes[i]['pnfiles']) == null" src="/assets/imgs/logo.png" />
                  <div class="rtcnt">
                    <h4 (click)="zoomimg(activeNote[i])" class="Btitle ion-margin-top ion-padding">{{activeNote[i]['pnData']['deviceCreatedTS']}}</h4>
                    <p (click)="zoomimg(activeNote[i])" class="ion-padding-bottom not">{{activeNote[i]['pnData']['note']}}</p> 
                    <div class="ion-margin-top ion-padding-top">
                    <span  (click)="zoomimg(activeNote[i])" class="authnm">{{activeNote[i]['pnData']['userName']}}</span>
                    <div class="ftrcnt">
                      <div  (click)="zoomimg(activeNote[i])" class="chatico"><span class="notlbl">0</span><ion-icon name="chatbubbles-outline"></ion-icon></div>
                       <div  (click)="zoomimg(activeNote[i])" class="imgico"> <span *ngIf="(this.activeNote[i]['pnfiles'])" class="notlbl im">{{this.activeNote[i]['pnfiles'].length}}</span><ion-icon name="images-outline"></ion-icon></div>
                       <ion-icon class="shrico" (click)="shareOn ([activeNote[i]])" name="share-social-outline"></ion-icon>
                     </div>
                    </div>
                     </div>
                     </div>
              </ion-item> -->
    </ion-slide>

    <ion-slide *ngIf="(allLoaded) == false" class="Bscroll center_img center" >
      <div  class="xgCard center" (click)="loadMore()">
   
              
              <ion-grid>
                      <ion-row>
                          <ion-col class="center">
                              <ion-label class="fw600 ion-text-wrap center text15"  color="dark" style='display: block'  >Load More</ion-label>                                                                 
                          </ion-col>
                                      
                      </ion-row>
              </ion-grid>  
                         
    </div>
</ion-slide>
</ion-slides>
</div>

<div>
  <ion-row class="center">
    <ion-button
      class="margin10"
      expand="block"
      fill="solid"
      size="default"
      color="primary"
      (click)="toPhotoNote([activeNote[0]['pnData']['skuID']], [activeNote[0]['pnData']['articleDescription']])"
      routerDirection="forward"
    >
      ADD RESPONSE
      <ion-icon
        slot="end"
        name="camera-outline"
        class="storecheckinicon icon24"
      ></ion-icon>
    </ion-button>
  </ion-row>
 </div>


    <div *ngIf="(activeNote[0]['pnData']['skuID']) != 'STORE'">
    <ion-item color="logo">
       <ion-icon class="icon24 ion-no-margin" slot="start" name="analytics-outline"></ion-icon>
      <ion-label><span class="ion-float-left">SKU DATA</span></ion-label>
      <!-- <ion-label *ngIf="[live] == 'false'" > <span class="text09 fw500  ion-float-right">{{activeNote[0]["pnData"]["deviceCreatedTS"]}}</span></ion-label>  
        <ion-label *ngIf="[live] == 'true'"> <span  class="text09 fw500  ion-float-right">{{ skuInfo.SDC?.DataDate }}</span></ion-label>      
     -->
      </ion-item>
    <!-- <ion-item>
      <ion-icon name="calendar-outline" slot="start"></ion-icon>
      <ion-label><span class="ion-float-left">Date Format:</span></ion-label> -->
    
    
    
      <!-- <ion-select  placeholder="Historic (PhotoNote Date)" [(ngModel)]="frm_date" value="0" (ionChange)="dateChange(frm_date)" >    
          <ion-select-option value="0">Historic (PhotoNote Date)</ion-select-option>
          <ion-select-option value="1">Live</ion-select-option>             
        </ion-select>
    </ion-item> -->

          <ion-item>            
              <ion-icon class="ion-align-self-start" name="barcode-outline" slot="start" size="default"></ion-icon>
              <ion-label><span class="ion-float-left">Barcode(s)</span>
                  
                  <span class="ion-float-right">
                       {{ skuInfo.SDC?.Barcodes[0] }} 
                  </span>
                  
                     <br /> <span class="ion-float-right">
                          {{ skuInfo.SDC?.Barcodes[1] }} 
                      </span>
                  
                   <br />   <span class="ion-float-right">
                          {{ skuInfo.SDC?.Barcodes[2] }} 
                      </span>
                 
              </ion-label>        
          </ion-item>
  
          <ion-item>
              <ion-icon name="pricetag-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Article Number</span><span class="ion-float-right">{{ skuInfo.SDC?.ArticleNumber }} </span></ion-label>           
          </ion-item> 
  <ion-item>
              <ion-icon name="alert-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Status</span><span class="ion-float-right">{{ skuInfo.SDC?.SiteArticleStatus }} {{ skuInfo.SDC?.ArticleStatusDesc }}</span></ion-label>           
          </ion-item>
  
          <ion-item>
              <ion-icon name="grid-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Pack Size</span><span class="ion-float-right">{{ skuInfo.SDC?.PackSize }}</span></ion-label>            
          </ion-item>
  
          <ion-item>
              
              <ion-icon name="cube-outline" slot="start" ></ion-icon>
              <ion-label><span slot="left">Store Stock on Hand</span>
             
               <h3 slot="start"> 
                  <ion-grid class="ion-padding"> 
                      <ion-row>                    
                          <ion-col size="6" class="ion-align-items-start"><div>as Units</div></ion-col>
                          <ion-col  class="ion-align-items-end"><div class="ion-float-right">{{ skuInfo.SDC?.SOHQty }}</div></ion-col>
                      </ion-row>
                      <ion-row>                    
                          <ion-col size="6" class="ion-align-items-start"><div>as Cases</div></ion-col>
                          <ion-col  class="ion-align-items-end"><div class="ion-float-right">{{ skuInfo.SDC?.SOHQtyCase }}</div></ion-col>
                      </ion-row>
                  </ion-grid>
              </h3>
            
              </ion-label>        
          </ion-item>
          <ion-item>
              
              <ion-icon name="cube-outline" slot="start"></ion-icon>
              
              <ion-label>DC Stock <ion-badge class="text07 ion-float-right" [color]="skuInfo.DCDataDate == skuInfo.SDC?.DataDate ? 'primary' : 'warning'" slot="end">{{ skuInfo.DCDataDate }}</ion-badge><br/>
                  <div>
                      
                          <ion-grid class="ion-padding" *ngFor="let dc of skuInfo.DCSDC"> 
                              <ion-row >
                                  <span class="ion-float-left bold fw500">{{dc.DCName}}</span>
                              </ion-row>
                              <ion-row>
                                  <ion-col ><div >as Units</div></ion-col><ion-col  class="ion-align-items-end">
                                      <div class="ion-float-right">{{dc.SOHQty}}</div></ion-col>
                              </ion-row>
                              <ion-row> 
                                  <ion-col><div>as Cases</div></ion-col><ion-col  class="ion-align-items-end">
                                      <div class="ion-float-right">{{dc.SOHQtyCase}}</div>
                                  </ion-col>
                              </ion-row>                       
                          </ion-grid>            
                  </div>
              </ion-label>                   
          </ion-item> 
  
  
          <ion-item>
                  <ion-icon name="cash-outline" slot="start"></ion-icon>
                  <ion-label><span class="ion-float-left">Retail Price</span><span class="ion-float-right">{{ skuInfo.SDC?.Price }}</span></ion-label>            
              </ion-item> 
       
          <ion-item>
            <ion-icon name="calendar-outline" slot="start"></ion-icon>
            <ion-label><span class="ion-float-left">Days Cover</span><span class="ion-float-right">{{ skuInfo.SDC?.ChannelDaysCover }}</span></ion-label>          
          </ion-item> 
  
          <ion-item>
              <ion-icon name="basket-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Daily Rate Of Sale</span><span class="ion-float-right">{{ skuInfo.SDC?.ChannelDROS }}</span></ion-label>          
          </ion-item> 
          <ion-item>
              <ion-icon name="cart-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Sold</span><span class="ion-float-right">{{ skuInfo.SDC?.DateLastSoldFmt }}</span></ion-label>          
          </ion-item> 
          
  
          <ion-item>
              <ion-icon name="download-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Received</span><span class="ion-float-right">{{ skuInfo.SDC?.DateLastReceivedFmt }}</span></ion-label>          
          </ion-item>
          
          <ion-item >
              <ion-icon name="today-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Ordered</span><span class="ion-float-right">{{ skuInfo.SDC?.DateLastOrderedFmt }}</span></ion-label>          
          </ion-item>  
          <ion-item>
              <ion-icon name="cart-outline" slot="start"></ion-icon>
              <ion-label><span class="ion-float-left">Units Sold 7 Days</span><span class="ion-float-right">{{ skuInfo.SALES?.TotalSalesUnitsPeriod }}</span></ion-label>          
          </ion-item>
         
      
  
        <ion-row>
                <div class="chartblock">       
                    <div echarts [options]="chartoptions" [merge]="chartdatas" (chartInit)="onChartInit($event)" class="chartdata" style="width: 100%;height:100%;"></div>
                </div>
        </ion-row>   
      </div>

    </div>
</ion-content>
<!-- <ion-footer  >
 
</ion-footer> -->
<!-- <ion-footer  id="liveContent">
  <ion-row class="center" *ngFor="let n of activeNote">
    <ion-button class="margin10" expand="block" fill="solid" size="default" color="primary" tappable routerDirection="forward"  routerLink="/members/skuinfo/{{activeNote[0]['pnData']['skuID']}}" >
        LIVE SKU DATA
        <ion-icon  slot="end" name="bookmark-outline" class="storecheckinicon icon24" ></ion-icon>
      </ion-button>
    </ion-row> 
</ion-footer> -->