import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, MenuController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiQueryService } from './../../services/apiquery.service';
import {GlobalmsgService} from './../../services/globalmsg.service';
import { Storage } from '@ionic/storage';
import { isArray } from 'util';
import { ImgPreviewPage } from '../img-preview/img-preview.page';
import { calcProjectFileAndBasePath } from '@angular/compiler-cli';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Plugins } from '@capacitor/core';
import { DataService } from 'src/app/services/data.service';
const { Share } = Plugins;
import { File } from '@ionic-native/file/ngx';
//import { TaskModalPage } from '../task-modal/task-modal.page';


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.page.html',
  styleUrls: ['./tasks.page.scss'],
})
export class TasksPage implements OnInit {

  activeTasks: any[];
  ArticleNumber: any;
  selectedStore: any = {};  
  gallery: any = []; 
  showSpinner: boolean = true;
  timerInitChart: any;
  isLoading: boolean = false;
  showSearchBar: boolean = false;
  
      photoNotes: any = [];
      photoCount: any = [];
 
  activeSupplierName: Promise<any>;
  skuInfo: any;
  photoDate: any = [];
  dateGroup: any = [];
  sku: any;
  dates: any 
  photoDateCount: any = [];
  photoNoteArray: any= [];
  pnimgcount: any[];
  windowNavigator: any;
  pnArray: any = [];
  searchTerm: any = '';
  pnTest: any =[1, 2, 3, 4, 5]
  
  photoNotesPersonal: any = [];
  photoCountPersonal: any = [];
  photoDateCountPersonal: any = [];
  photoDatePersonal: any = [];
  datesPersonal: any = [];
  photoNoteArrayPersonal: any = [];

  photoNotesUnread: any = [];
  photoCountUnread: any = [];
  photoDateCountUnread: any = [];
  photoDateUnread: any = [];
  datesUnread: any = [];
  photoNoteArrayUnread: any = [];
  frm_filter: any;
  noResults: boolean;
  activeType: any;
  loadLength: number = 5;
  stored: any = {};
  pnArrayT: any = [];
  allLoaded: boolean = false;
  linkedNotes: any = [];
  activeUser: any[];
  taskType: any = 'assigned';
  storeID: any;
  availableTasks: any[] = [];
  allTasks: any[] = [];
  assignedCount: number = 0;
  userID: any;
  myTasks: any[] =[];
  assignedTasks: any[] =[];
  state: string = 'assigned';
  taskBacklog: any[];
  taskQueue: any[];
  public show = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    private apiqueryService: ApiQueryService,
    private globalmsgService: GlobalmsgService,
    private storage: Storage,
    private router: Router,
    private socialSharing: SocialSharing, 
    private modalController: ModalController,
    private dataService: DataService,
    // private socialSharing: SocialSharing, 
    private file:File,
    private modalCtrl: ModalController
  
  ) { 

      

  }

 async ngOnInit() {


   

    //  this.refreshTasks();
  }



  ngAfterViewInit(){
      
  }

  async toTaskView(taskID){
    await this.storage.set('taskID', taskID);
    this.router.navigate(['/members/taskview'])
  }

  async toPhotoNoteTask(i: number){
    console.log(i)
    this.storage.set('linkedArticleNumber', this.myTasks[i]["skuID"]);
    this.storage.set('linkedArticleDetails', this.myTasks[i]["articleDescription"]+'\n['+this.myTasks[i]["skuID"]+'] ');
    this.storage.set('skuPhoto', true)
    this.storage.set('taskPN', true)
    this.storage.set('task', this.myTasks[i])
    // this.storage.set('appRoute', this.availableTasks[i]["flagID"]);
    await this.router.navigate(['/members/photonote']);
  }

 removeTask(i){
   this.apiqueryService.updateTaskStatus(this.myTasks[i]['taskID'], 0).toPromise().then((result) => {      
      console.log(result);
      this.refreshTasks();
    }); 
    // this.myTasks.splice(this.myTasks[i] ,1);
  }

  async getStoredValue(key: string) {
    return await this.storage.get(key).then((val) => {
          // console.log('Storage get: ' + key + ' ', val);
          this.stored[key] = '';
          this.stored[key] = val;
          return this.stored[key];
    }).catch((error) => {
          console.error(' getStorageValue Storage get for ' + key + ' ', error);
    });
  }


 async doRefresh(event) {
   console.log(event)
   await this.ionViewWillEnter();


event.target.complete()
  }
  // ionViewWillLeave(){
  //   this.loadReset('all')
  //   this.frm_filter.value = 'all'
  //   this.frm_filter = 'all'
  // }

 async ionViewWillEnter(){
  this.storeID = await this.getStorageValue('STORE_ID');
  console.log(this.storeID)

  let supplierID = await this.getStorageValue('ActiveSupplier');
 await this.apiqueryService.getTasks(this.storeID, supplierID).toPromise().then((result) => {
console.log('getTask result: ' , result)
this.activeTasks = result;

 })
//     this.refreshTasks();
//     // 
//     this.getSkuData(); 
//   await  this.getPhotoNotes();

//     await this.loadReset('all')
// this.frm_filter = 'all'
  }



  async getPhotoNotes() {

    this.isLoading = true
    await this.getStoreParams();
    await this.getStoredAccountInfo();
    this.activeUser = 
   this.photoNotes = [];
   this.photoCount = [];
   this.photoDateCount = [];
   this.photoDate = [];
   this.photoNoteArray = [];
   this.dates = [];
   this.dateGroup = [];
   this.photoNotesPersonal = [];
   this.photoCountPersonal = [];
   this.photoDateCountPersonal = [];
   this.photoDatePersonal = [];
   this.datesPersonal = [];
   this.photoNoteArrayPersonal= [];
   this.pnArray = [];
   this.pnArrayT = [];
   this.photoNotesUnread = [];
   this.photoCountUnread = [];
   this.photoDateCountUnread = [];
   this.photoDateUnread = [];
   this.datesUnread = [];
   this.photoNoteArrayUnread = [];

let supplierID = await this.getStorageValue('ActiveSupplier');
console.log('start query')
     await this.apiqueryService.getStoreTasks(this.selectedStore['STORE_ID']).toPromise().then((result) => {      
       this.photoNotes.push(result); 
       console.log(result);
     });  
     if (this.photoNotes[0] == null){
      this.isLoading = false
      this.noResults   = true
    }

    if (this.photoNotes[0] != null ){
      console.log(this.photoNotes[0])
       for  (let i = 0; i < 10000; i++){
         // for (let x = 0; x < this.photoNotes.length; x++){
         if (this.photoNotes[0][i] != null ){
           this.photoCount.push(i)
         // }
         }
       }
       }

    await this.photoCount.sort((a: number, b: number) => b-a); 
    
     for (let x = 0; x < this.photoCount.length; x++){
      this.photoNotes[0][(this.photoCount[x])]['tnData']['linkedCount'] = 0
      this.photoNotes[0][(this.photoCount[x])]['tnData']['deviceCreatedTS'] = this.photoNotes[0][(this.photoCount[x])]['tnData']['deviceCreatedTS'].split(' ')[0];
       
      for (let i = 0; i < this.photoCount.length; i++){
if (this.photoNotes[0][(this.photoCount[x])]['tnData']['tnID'] == this.photoNotes[0][(this.photoCount[i])]['tnData']['linkedID']){
  this.photoNotes[0][(this.photoCount[x])]['tnData']['linkedCount'] = this.photoNotes[0][(this.photoCount[x])]['tnData']['linkedCount'] + 1
if(this.photoNotes[0][(this.photoCount[i])]['tnData']['lastSeen'] == 'unread'){
  this.photoNotes[0][(this.photoCount[x])]['tnData']['lastSeen'] = 'unread'
}
}

      }

      if (this.photoNotes[0][(this.photoCount[x])]['tnfiles'] != null){
      
      for (let i = 0; i < this.photoNotes[0][(this.photoCount[x])]['tnfiles'].length; i++){

      this.photoNotes[0][(this.photoCount[x])]['tnfiles'][i]['filePath'] = "https://marketforce.co.za/V2/api" + this.photoNotes[0][(this.photoCount[x])]['tnfiles'][i]['filePath'].replace('..','');
     }}

    if (this.photoNotes[0][(this.photoCount[x])]['tnData']['linkedID'] == 0 || this.photoNotes[0][(this.photoCount[x])]['tnData']['linkedID'] == null){
     this.photoNoteArray.push(this.photoNotes[0][(this.photoCount[x])])
   }
   
    
  
    }

    this.setViewType('all');

    


//PERSONAL PHOTONOTES HERE

for (let i = 0; i < this.photoNoteArray.length; i++){
  if (this.photoNoteArray[i]['tnData']['relevant'] == 'yes'){
    this.photoNoteArrayPersonal.push(this.photoNoteArray[i])
  }
};


//  this.photoNotesPersonal = this.photoNotes; 
//   console.log(this.photoNotesPersonal);
 

// for (let i = 0; i < 10000; i++){
//  if (this.photoNotesPersonal[0][i] != null){
//    this.photoCountPersonal.push(i)
 
// }
// }

// await this.photoCountPersonal.sort((a: number, b: number) => b-a); 

// for (let x = 0; x < this.photoCountPersonal.length; x++){

//  this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['deviceCreatedTS'] = this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['deviceCreatedTS'].split(' ')[0];
//  this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['linkedCount'] = 0


 
//  for (let i = 0; i < this.photoCountPersonal.length; i++){
//   if (this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['tnID'] == this.photoNotesPersonal[0][(this.photoCountPersonal[i])]['tnData']['linkedID']){
//     this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['linkedCount'] = this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['linkedCount'] + 1
    
//    }
  
//         }

// if (this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['linkedID'] == 0 || this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['linkedID'] == null && this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['relevant'] == 'yes'){
// this.photoNoteArrayPersonal.push(this.photoNotesPersonal[0][(this.photoCountPersonal[x])])
// }

// }

//UNREAD HEREsupplierID, storeID, lastSeen = 1

for (let i = 0; i < this.photoNoteArray.length; i++){
  if (this.photoNoteArray[i]['tnData']['relevant'] == 'yes' && this.photoNoteArray[i]['tnData']['lastSeen'] == 'unread' ){
    this.photoNoteArrayUnread.push(this.photoNoteArray[i])
  }
};

//  this.photoNotesUnread = this.photoNotes;


// for (let i = 0; i < 10000; i++){
//  if (this.photoNotesUnread[0][i] != null){
//    this.photoCountUnread.push(i)
 
// }
// }

// await this.photoCountUnread.sort((a: number, b: number) => b-a); 

// for (let x = 0; x < this.photoCountUnread.length; x++){
//   this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['linkedCount'] = 0
// //  if (this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnfiles'] != null ){

//   for (let i = 0; i < this.photoCountUnread.length; i++){
//     if (this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['tnID'] == this.photoNotesUnread[0][(this.photoCountUnread[i])]['tnData']['linkedID']){
//       this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['linkedCount'] = this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['linkedCount'] + 1
//     }
    
//           }

//  this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['deviceCreatedTS'] = this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['deviceCreatedTS'].split(' ')[0];
// //  }

// //  if (this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnfiles'] != null){
 
// //  for (let i = 0; i < this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnfiles'].length; i++){

// //  this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnfiles'][i]['filePath'] = "https://marketforce.co.za/V2/api" + this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnfiles'][i]['filePath'].replace('..','');
// // }}
// if (this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['lastSeen'] == 'unread' && this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['linkedID'] == 0 || this.photoNotesUnread[0][(this.photoCountUnread[x])]['tnData']['linkedID'] == null && this.photoNotesPersonal[0][(this.photoCountPersonal[x])]['tnData']['relevant'] == 'yes'){
// this.photoNoteArrayUnread.push(this.photoNotesUnread[0][(this.photoCountUnread[x])])
// }
// }





  }

 onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

 async getStoredAccountInfo() {
  await this.getStorageValue('ACCOUNT_FirstName');
  await this.getStorageValue('ACCOUNT_LastName');
  await this.getStorageValue('ACCOUNT_Mobile'); 
  await this.getStorageValue('ACCOUNT_TypeDescription');
  await this.getStorageValue('ACCOUNT_Email');
  
  }

  toComplete(){
  this.state = 'complete'
   console.log("task has been completed!")
  }

  ionViewWillLeave(){
    this.storage.set('taskState', 'assigned')
  }

 async refreshTasks(){
  await  this.getStoredValue('STORE_ID').then( _ => {
    this.storeID = this.stored.STORE_ID 
   
    })

  await this.getStoredValue('taskState').then( _ => {  
    console.log(this.stored.taskState)
  this.state = this.stored.taskState    
      })
        console.log("tasks have been refreshed")
    await this.apiqueryService.getAllTasks(this.storeID).toPromise().then((result) => {      
      console.log(result);
      this.allTasks = result

      this.myTasks = []
      this.assignedTasks = []
      this.availableTasks = [];
     
      this.getStoredValue('ACCOUNT_ID').then( _ => {     
        this.userID = this.stored.ACCOUNT_ID })    
      console.log(this.userID)
      for (let i = 0; i < this.allTasks.length; i++){
       
      if (this.allTasks[i]["taskStatus"] == 'Available' ){
      this.availableTasks.push(this.allTasks[i])
      }
      if (this.allTasks[i]["assignedID"] == this.userID && this.allTasks[i]["taskStatus"] == 'Active'){
        this.myTasks.push(this.allTasks[i])
      }
      if (this.allTasks[i]["assignedID"] != this.userID && this.allTasks[i]["assignedID"] != null && this.allTasks[i]["taskStatus"] == 'Active'){
        this.assignedTasks.push(this.allTasks[i])
      }    
      }
  
     this.getStoredValue('taskBacklog').then( _ => {  
      this.taskQueue = [];  
      this.show = false 
     
        this.taskBacklog = this.stored.taskBacklog
        console.log('check taskBacklog ', this.taskBacklog)
        for (let x = 0; x < this.myTasks.length; x++){
          this.taskBacklog.forEach((element,index)=>{
            console.log(element)
            console.log(this.myTasks)
            if(element==this.myTasks[x]['taskID']) this.taskQueue.push(this.myTasks[x])
            if(element==this.myTasks[x]['taskID']) this.myTasks.splice(x,1);
            
         });
         console.log('check taskQueue ', this.taskQueue)
         setTimeout(() => this.show = true);
        }
     
      })
 
      this.assignedCount = this.assignedTasks.length + this.myTasks.length
  
    }); 
   
    
   

    //  await this.ionViewWillEnter()
  }

  // async taskModal(task: any, taskArray: any) {
  //   const modal = await this.modalController.create({
  //     component: TaskModalPage,
  //     cssClass: 'task-modal-class',
  //     componentProps: {
  //       'task': taskArray
  //     }
  //   });
  //   modal.onDidDismiss()
  //   .then((data) => {
  //     const taskStatus = data['data']; // Here's your selected status update!
  //    console.log(taskStatus)
     
  //     if (taskStatus == 'Active'){
  //       this.refreshTasks();
  //     // this.getStoredValue('ACCOUNT_ID').then( _ => {     
  //     //     this.userID = this.stored.ACCOUNT_ID 
  //     //     this.availableTasks[task]['assignedID'] = this.userID  
  //     //  this.availableTasks[task]['taskStatus'] = 'Active'})

  //     //   this.myTasks.push(this.availableTasks[task])
  //     //  this.availableTasks.splice(task ,1);
       
  //     }
  
  // });
  //   return await modal.present();
  // }


  @ViewChild('search') search : any;
  toggleSearchBar() {
    console.log('Show/Hide Search Bar');
    if(this.showSearchBar === true) {
        this.showSearchBar = false;
    } else {
      this.showSearchBar = true;
      this.focus();
    }
  }
  
  focus(): void {
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }

  toPhotoNote(){
    this.storage.set('taskPN', false)
    this.router.navigate(['/members/photonote']);
  }

  toHistSku(skuID){
    this.storage.set('historic', true)
    this.router.navigate(['/members/skuinfo/' + skuID]);
  }
 
  async getSkuData() {

    this.isLoading = true;
   
    await this.getStoreParams();
          // console.log('CALLING API');
          //    await this.apiqueryService.getSkuInfo(this.selectedStore['STORE_ID'], this.selectedStore['STORE_CHANNEL'],this.ArticleNumber).then((result) => {
          //     this.skuInfo = result;
              
          //     var d:any;             

          //     for(d in this.gallery.SDC) {
          //           console.debug('gallery TESTING VALUES: ',this.gallery.SDC[d]) 
          //           if(typeof this.gallery.SDC[d] === 'undefined' || this.gallery.SDC[d] === null || this.gallery.SDC[d].length == 0) {
          //             console.debug('galleryDATA ELEMENT IS undef,null or not set', this.gallery.SDC[d]);
          //             this.gallery.SDC[d] = 'Not Available';
          //           }       
          //     }
             
          //     console.log('API gallery DONE', result);
          //     console.log('gallery ARRAY', this.gallery);             
          // }).then((result) => {

            
          //   return result;

          // });
          // ;
    //  })
 
    
  }

  setFilteredItems() {

    this.setViewType(this.activeType)
      
  }

loadReset(type:any){
    this.loadLength = 5;
    console.log('load reset')
    this.allLoaded = false;
    this.setViewType(type)
}

loadMore(){
this.loadLength = this.loadLength + 5;
if (this.loadLength >= this.pnArrayT.length ){
  this.loadLength = this.pnArrayT.length;
  this.allLoaded = true;
}

this.setViewType(this.activeType)
  }

 setViewType(type: any) {
    this.activeType = type
    this.isLoading = true
    this.dates=[]
console.log(this.photoNoteArray)
    // this.listingType = type;
 this.noResults   = false;

    switch(type) {
        case 'all': {
      
         
          
                 this.pnArrayT = this.dataService.filterTNs(this.searchTerm, this.photoNoteArray)
                 if (this.loadLength >= this.pnArrayT.length ){
                  this.loadLength = this.pnArrayT.length;
                  this.allLoaded = true;
                }
                //  this.pnArray = this.dataService.filterItems(this.searchTerm, this.photoNoteArray)
                this.pnArray = this.pnArrayT.slice(0, this.loadLength)
                for (let x = 0; x < this.loadLength; x++){
              
                    this.pnArray[x]['tnData']['deviceCreatedTS'] = this.pnArray[x]['tnData']['deviceCreatedTS'].split(' ')[0];
            this.dates.push(this.pnArray[x]['tnData']['deviceCreatedTS'])
            this.isLoading = false
                  }
this.dateGroup = this.dates.filter(this.onlyUnique)
this.activeType = 'all'
 
                  break;
              }
        case 'personal': {
          this.isLoading = true
          // this.pnArray = this.dataService.filterItems(this.searchTerm, this.photoNoteArrayPersonal)
          this.pnArrayT = this.dataService.filterPNs(this.searchTerm, this.photoNoteArrayPersonal)
          if (this.loadLength >= this.pnArrayT.length ){
            this.loadLength = this.pnArrayT.length;
            this.allLoaded = true;
          }
          this.pnArray = this.pnArrayT.slice(0, this.loadLength)
          for (let x = 0; x < this.loadLength; x++){
        
              this.pnArray[x]['tnData']['deviceCreatedTS'] = this.pnArray[x]['tnData']['deviceCreatedTS'].split(' ')[0];
            this.dates.push(this.pnArray[x]['tnData']['deviceCreatedTS'])
            this.isLoading = false
            }
this.dateGroup = this.dates.filter(this.onlyUnique)
                // this.routeable = true;
                this.activeType = 'personal' 
                
              console.log (this.pnArrayT)
                break;
            }

            case 'unread': {
              this.isLoading = true
              this.pnArrayT = this.dataService.filterPNs(this.searchTerm, this.photoNoteArrayUnread)
              if (this.loadLength >= this.pnArrayT.length ){
                this.loadLength = this.pnArrayT.length;
                this.allLoaded = true;
              }
              this.pnArray = this.pnArrayT.slice(0, this.loadLength)
              for (let x = 0; x < this.loadLength; x++){
          
                  this.pnArray[x]['tnData']['deviceCreatedTS'] = this.pnArray[x]['tnData']['deviceCreatedTS'].split(' ')[0];
            this.dates.push(this.pnArray[x]['tnData']['deviceCreatedTS'])
            this.isLoading = false
                }
this.dateGroup = this.dates.filter(this.onlyUnique)
              this.activeType = 'unread'  
              
                    break;
                }
       
        default: {
          this.isLoading = true
          // this.pnArray = this.dataService.filterItems(this.searchTerm, this.photoNoteArray)
          this.pnArrayT = this.dataService.filterPNs(this.searchTerm, this.photoNoteArray)
          if (this.loadLength >= this.pnArrayT.length ){
            this.loadLength = this.pnArrayT.length;
            this.allLoaded = true;
          }
          this.pnArray = this.pnArrayT.slice(0, this.loadLength)
          for (let x = 0; x < this.loadLength; x++){
  
              this.pnArray[x]['tnData']['deviceCreatedTS'] = this.pnArray[x]['tnData']['deviceCreatedTS'].split(' ')[0];
          this.dates.push(this.pnArray[x]['tnData']['deviceCreatedTS'])
          this.isLoading = false
            }
      console.log(this.pnArray)
this.dateGroup = this.dates.filter(this.onlyUnique)
this.activeType = 'all'

          break;
          
        } 

    }

    if(Object.keys(this.pnArray).length === 0) {
      // this.noResults = true;
    
        this.noResults = true;
        this.isLoading = false
   
    } 

   
    
}

  async getStorageValue(key: string) {
    return await this.storage.get(key).then((val) => {
          console.log('Storage get: ' + key + ' ', val);            
          this.selectedStore[key] = "";
          this.selectedStore[key] = val;
          return this.selectedStore[key];
    }).catch((error) => {
          console.log('ERROR: Storage get for ' + key + ' ', error);
    });
 }

 async getStoreParams() {
    await this.getStorageValue('STORE_ID');
    await this.getStorageValue('STORE_NAME');
    await this.getStorageValue('STORE_CHANNEL'); 
    await this.getStorageValue('STORE_BANNER');
    await this.getStorageValue('SELECT_GEO_LAT');
    await this.getStorageValue('SELECT_GEO_LON');
    await this.getStorageValue('SELECT_GEO_ACC');     
                   
 }



 toPersonal(){
  // let schedule = document.getElementById("scheduleContent");
  // schedule.style.display = "inline"

  // let dashboard = document.getElementById("dashboardContent");
  // dashboard.style.display = "none"

  // let dashBtn = document.getElementById("dashboardBtn");
  // dashBtn.style.color = "black"
  // dashBtn.style.backgroundColor = "white"

  // let schedBtn = document.getElementById("scheduleBtn");
  // schedBtn.style.color = "white"
  // schedBtn.style.backgroundColor = "#018ee4"
}






async zoomimg(note){
// async zoomimg(img){
  note['tnData']['lastSeen'] = 'read'
  // img = "https://marketforce.co.za/V2/api" + img
this.linkedNotes = [];
  for (let x = 0; x < this.photoCount.length; x++){
if (this.photoNotes[0][(this.photoCount[x])]['tnData']['linkedID'] == note['tnData']['tnID']){
  this.linkedNotes.push(this.photoNotes[0][(this.photoCount[x])])
}
  }
await this.storage.set('linkedNotes', this.linkedNotes)
  await this.storage.set('activeNote', note)
   this.router.navigate(['/members/photobook']);

}
async getpnimg(photonote){
  if (photonote[0].tnfiles != null){
    for (let i = 0; i < photonote[0].tnfiles.length; i++){
    // x += photonote[0].tnfiles[i].filePath;
  }
}
}

setTaskType(type: any) {

   this.taskType = type;
  this.noResults   = false;

  // switch(type) {
  //     case 'assigned': {
  //               // this.listArray = this.dataService.filterItems(this.searchTerm,this.listings.StoreArticlesActive);
  //               // this.routeable = true;                  
  //               break;
  //           }
  //     case 'available': {

  //             // this.listArray = this.dataService.filterItems(this.searchTerm,this.listings.StoreArticlesInactive);
  //             // this.routeable = true;                
  //             break;
  //         }
  //     case 'complete': {
       
  //             // this.listArray = this.dataService.filterItems(this.searchTerm,this.listings.StoreArticlesUnlisted);
  //             // this.routeable = false;                
  //             break;
  //       }
  //     default: {

  //             // this.listArray = this.dataService.filterItems(this.searchTerm,this.listings.StoreArticlesActive);
  //             // this.routeable = true;
  //             break;
  //     } 
  // }

  // if(Object.keys(this.listArray).length === 0) {
  //   this.noResults = true;
  // } 

 
  
}



// share(photoNote){
//   this.globalmsgService.shareTn(photoNote)
// }

}


 