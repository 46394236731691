import { AlertController } from '@ionic/angular';
import { Injectable, Output,  } from '@angular/core';
import { HttpClient   } from '@angular/common/http';
import { Observable, of, observable  } from 'rxjs';
import { map, catchError, retry, tap  } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Storage } from '@ionic/storage';
//import { environment } from '../../environments/environment';

//import { BehaviorSubject } from 'rxjs';
 
// Typescript custom enum for search types (optional)
/*export enum SearchChannel {
  all = '',
  PNP = 'PNP',
  SRCH = 'SRCH'
  
}*/
  
@Injectable({
  providedIn: 'root'
})


export class ApiQueryService {
  url = 'https://marketforce.co.za/V2/api/';
  testurl = 'https://marketforce.co.za/V2/apidev/'
  data: any = {};
  stored: any = {};
  supplierID: any;
  cacheData: any = {};


  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(private http: HttpClient,  private authService: AuthenticationService, private storage: Storage, private alertController: AlertController) { }

  /**
  * Get data from the Store SEARCH Api
  * map the result to return only the results that we need
  * 
  * @param {string} search Search Term
  * @returns Observable with the search results
  */
  apiQuery(apiaction: string, search: string): Observable<any> {
    return this.http.get(`${this.url}${apiaction}/?search=${encodeURI(search)}&_ts=${+new Date}`).pipe(
       
      map(results => {
          // console.log(results);
          // console.log('HTTP STATUS', results);
          
          return results['DataResult'];
          }
    ));
  }

  sendRegRequest(params: any): Observable<any> {
      return this.http.post(`${this.url}register/`,{ params}).pipe(
       
      map(results => {
          // console.log(results);
          // console.log('HTTP STATUS', results);
          return results['DataResult'];
          }
    ));
  }
  
//   checkifonline(params: any): Observable<any> {
//     return this.http.post(`${this.url}register/`,{ params}).pipe(
     
//     map(results => {
//         // console.log(results);
        
//         return results['DataResult'];
//         }
//   ));
// }

  getUserStoreSuppliers(storeid: string, channel: string): Observable<any> {
    return this.http.get(`${this.url}userstoresuppliers/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&_ts=${+new Date}`).pipe(
       
      map(results => {
          return results['DataResult']['LinkedSuppliers'];
        })
    );      
  }

getResolution() : Observable<any>{
  return this.http.get(`${this.url}functionParams?res=1`).pipe(
       
    map(results => {
        return results['DataResult'];
      })
  );      
    }

  // getOpenSeetion(accountid:string, outts:string): Observable<any>{
  //      return this.http.get(`${this.url}UserAccount/?AccountID`)
  // }

  searchSKU(apiaction: string, storeid: string, channel:string, search: string): Observable<any> {
    return this.http.get(`${this.url}${apiaction}/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&search=${encodeURI(search)}`).pipe(
       
      map(results => results['DataResult'])
    );
  }

  getStoreInfo(storeid: string, channel:string): Observable<any> {
    return this.http.get(`${this.url}storeinfo/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&_ts=${+new Date}`).pipe(
       
      map(results => results['DataResult'])
    );
  }

  /*async getStoreClientStockLevels(storeid: string, channel: string, summary: string): Promise<any> {

        if(summary == '1') {  var apiCallName = 'storeClientStockLevelsSummary';
        } else {  var apiCallName = 'storeClientStockLevels'; };

      await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function?           
            return await this.getSupplierStoreCacheData(storeid, apiCallName)
                .then(results => {

                      if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) { 
                        return results;
                    
                      }  else {
                            return this.http.get(`${this.url}stocklevels/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
                               
                              map(results => { 
                                      return results['DataResult']; 
                                    })).toPromise();
                                  }
                              });
 } */
 getStoreClientStockLevels(storeid: string, channel: string, summary: string): Observable<string> {
  return this.http.get(`${this.url}stocklevels/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
    retry(3),       
    map(results => { 
      return results['DataResult'];
    }
  ))
}

getStoreListingInfo(storeid: string, channel: string, summary: string): Observable<string> {

  return this.http.get(`${this.url}storelistings/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
  map(results => {                          
       // this.setSupplierStoreCacheData(storeid, apiCallName, JSON.stringify(results['DataResult']));                                                                            
        return results['DataResult'];
                   
              }
           ))
}

/**
 * async getStoreListingInfo(storeid: string, channel: string, summary: string): Promise<any> {

  //  if(summary == '1') {  var apiCallName = 'storeListingInfoSummary';
  //    } else {  var apiCallName = 'storeListingInfo'; };
  if(summary == '1') {          
      var apiCallName = 'storeListingInfoSummary';
  } else {   
      var apiCallName = 'storeListingInfo';
  };

    await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function?           
    return await this.getSupplierStoreCacheData(storeid, apiCallName)
        .then(results => {

              if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) {
                return results;
            
              }  else {
  
                return this.http.get(`${this.url}storelistings/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
                  
                        map(results => {                          
                              this.setSupplierStoreCacheData(storeid, apiCallName, JSON.stringify(results['DataResult']));                                                                            
                              return results['DataResult'];
                      })).toPromise();
            }
        });
} 
*/  
 
async getSkuInfo(storeid: string, channel: string, skuid: string): Promise<any> {
 console.log(new Date)
  let apiCallName = 'getSkuInfo';

  await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function?           
  return await this.getSupplierStoreSkuCacheData(storeid, skuid, apiCallName)
      .then(results => {

            if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) { 
              return results;
          
            }  else {

            return this.http.get(`${this.url}skuinfo/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&skuid=${encodeURI(skuid)}&_ts=${+new Date}`).pipe(
              retry(3), 
             
              map(results => { 
                      this.setSupplierStoreSkuCacheData(storeid, skuid, apiCallName, JSON.stringify(results['DataResult'])); 
                      return results['DataResult']; 
              })).toPromise();
            }
          });
         
  } 

  async getHistSkuInfo(storeid: string, channel: string, skuid: string): Promise<any> {

    let apiCallName = 'getSkuInfo';
  
    await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function?           
    return await this.getSupplierStoreSkuCacheData(storeid, skuid, apiCallName)
        .then(results => {
  
              if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) { 
                return results;
            
              }  else {
  
              return this.http.get(`${this.url}skuinfo/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&skuid=${encodeURI(skuid)}&_ts=${+new Date}`).pipe(
                retry(3), 
                map(results => { 
                        this.setSupplierStoreSkuCacheData(storeid, skuid, apiCallName, JSON.stringify(results['DataResult'])); 
                        return results['DataResult']; 
                })).toPromise();
              }
            });
    } 
  


  getStoreAgedStock(storeid: string, channel: string, summary: string): Observable<string> {
    return this.http.get(`${this.url}agedstock/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
       
      map(results => {              
              return results['DataResult']; 
      }))
  }

  getStoreRank(storeid: string, channel: string): Observable<string> {
    return this.http.get(`${this.url}storerank/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&_ts=${+new Date}`).pipe(
       
      map(results => {              
              return results['DataResult']; 
      }))
  }

  getSKUList(storeid: string, channel: string, type: string): Observable<any> {
    return this.http.get(`${this.url}storelistings/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&type=${encodeURI(type)}&summary=1&_ts=${+new Date}`).pipe(
       
      map(results => {
                //  this.storage.set('_cacheApiData['+this.storage.get+']', JSON.stringify(results)); 
              return results['DataResult'];
      }))
  }

  /*
  getSKUList(storeid: string, type: string): Observable<any> {
    return this.http.get(`${this.url}storelistings/?storeid=${encodeURI(storeid)}&type=${encodeURI(type)}&summary=1&_ts=${+new Date}`).pipe(
      retry(3), 
      map(results => {
              this.storage.set('_cacheApiData['+this.storage.get+']', JSON.stringify(results)); 
              return results['DataResult'];
      }))
  }*/

  

  getStoreClientOutOfStock(storeid: string, channel: string, summary: string): Observable<string> {
    console.log('OOS StoreID: ', storeid)
    return this.http.get(`${this.url}outofstock/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
       
      map(results => { 
        return results['DataResult']; 
    }))
  }  

 

  getStoreClientNoSales(storeid: string, channel: string, summary: string): Observable<string> {
    return this.http.get(`${this.url}nosales/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(       
      map(results => { 
        return results['DataResult']; 
    }))
  }

  getStoreClientSalesOut(storeid: string, channel: string, summary: any): Observable<string> {
    return this.http.get(`${this.url}salesout/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
      map(results => {  
        let articles  = results['DataResult'];
        console.log('API SALES', articles);
        return articles; 
      }))
  }

  getStoreClientArticleStatusChange(storeid: string, channel: string): Observable<string> {
    return this.http.get(`${this.url}statuschange/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&_ts=${+new Date}`).pipe(       
      map(results =>{
        return results['DataResult']; 
      }
    ))      
  }



  getStoreClientLowDaysCover(storeid: string, channel: string): Observable<string> {
    return this.http.get(`${this.url}lowdayscover/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&_ts=${+new Date}`).pipe(
       
      map(results => { 
              return results['DataResult']; 
      }))
  }

  getStoreClientLowDaysCoverSummary(storeid: string, channel: string): Observable<string> {
    return this.http.get(`${this.url}lowdayscover/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=1&_ts=${+new Date}`).pipe(
       
      map(results => { 
              return results['DataResult']; 
      }))
  }
  

  getStoreClientOrdersDelivery(storeid: string, channel: string, summary: string): Observable<string> {
    return this.http.get(`${this.url}orderdelivery/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
       
      map(results => { 
              return results['DataResult']; 
      }))
  }

  updateActiveSupplier(supplierID: number): Promise<any> {
      // console.log('API - update SupplierID', supplierID);
      let postData = {
              "supplierID": supplierID            
      }

      this.storage.set('ActiveSupplier',supplierID)
      .then(() => {
                this.getStorageValue('ActiveSupplier');
      });
      
      return this.http.post(`${this.url}updateactivesupplier/?_ts=${+new Date}`, postData).pipe(
        
            map(results => {
                  console.log('updateActiveSupplier API Response',results);
                  return results['DataResult'];
            })).toPromise();

            /*
            .catchError(e => {
              this.showAlert('Unable to set active supplier: '+e.error.msg);
              throw new Error(e);
            })
            */

  }

  logCheckIn(storeID: string, channel: string, supplierID: number, sessionID: any, inTS: any, outTS: any, lat: any, lng: any, acc: any, heading: any, speed: any): Observable<any> {   

    let postData = {
            "storeID": storeID,
            "channel": channel,
            "supplierID": supplierID,
            "sessionID": sessionID,
            "inTS": inTS,
            "outTS": outTS,
            "latitude": lat,
            "longitude": lng,
            "accuracy": acc,
            "heading": heading,
            "speed": speed          
    }
   
    return this.http.post(`${this.url}logCheckIn/?_ts=${+new Date}`, postData).pipe(      
      map(results => {
            console.debug('API RESULTS - SESSIONID', results)            
            return results;
      }))

  } 


  postTaskResponse(tnID: any, postData: any): Promise<any> {
console.log('survey post data: ', postData)
    return this.http.post(`${this.url}tasks/?taskNote=1&taskID=${encodeURI(tnID)}`, postData).pipe(
      
          map(results => {
                console.log('Post Task Response API Response',results);
                return results['DataResult'];
          })).toPromise();
  }

postPhotoNote(postData: any): Promise<any> {
  console.log('post data: ', postData)
    return this.http.post(`${this.url}postphotonote/?_ts=${+new Date}`, postData).pipe(
      
          map(results => {
                console.log('Post Photo Note API Response',results);
                return results['DataResult'];
                
          })).toPromise();
         
}

postShift(postData: any): Promise<any> {

  return this.http.post(`https://marketforce.co.za/V2/api/izonSchedulesUpdate/?add=1`, postData).pipe(
    
        map(results => {
              console.log('updated shift',results);
              return results['DataResult'];
        })).toPromise();

}


updateTaskStatus(tnID: any, type: any): Observable<string> {

  return this.http.get(`${this.url}tasks/?updateTasks=1&taskID=${encodeURI(tnID)}&type=${encodeURI(type)}`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}


getPhotoNotes(storeid: string, channel: string, skuid: string , supplierID, filter: string, lastSeen: string, summary: string ): Observable<string> {
  return this.http.get(`${this.url}photonotes/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&skuid=${encodeURI(skuid)}&SupplierID=${encodeURI(supplierID)}&filter=${encodeURI(filter)}&lastSeen=${encodeURI(lastSeen)}&_ts=${+new Date}`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}

getAllTasks(storeid: string): Observable<any> {
  return this.http.get(`${this.url}tasks/?autoTasks=1&storeid=${encodeURI(storeid)}`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}

// getPhotoNotes(storeid: string, channel: string, skuid: string , supplierID, summary: string ): Observable<string> {
//   return this.http.get(`${this.url}photonotes/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&skuid=${encodeURI(skuid)}&SupplierID=${encodeURI(supplierID)}&_ts=${+new Date}`).pipe(
     
//     map(results => {              
//             return results['DataResult']; 
            
//     }))
// }

// getStorePhotoNotes(storeid: string, channel: string, supplierID, summary: string ): Observable<string> {
//   return this.http.get(`${this.url}photonotes/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&SupplierID=${encodeURI(supplierID)}&_ts=${+new Date}`).pipe(
     
//     map(results => {              
//             return results['DataResult']; 
            
//     }))
// }
getStoreTasks(storeid: any): Observable<string> {
  return this.http.get(`${this.url}/tasks/?gallery=1&summary=0&storeid=${encodeURI(storeid)}`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}

getStorePhotoNotes(supplierID, storeid: string,  summary: string ): Observable<string> {
  return this.http.get(`${this.url}photonotes/?SupplierID=${encodeURI(supplierID)}&storeid=${encodeURI(storeid)}&summary=${encodeURI(summary)}&_ts=${+new Date}/`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}

getUnseenTaskCount(storeID: any): Observable<string> {
  return this.http.get(`${this.url}/tasks/?gallery=1&summary=1&storeid=${encodeURI(storeID)}`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}

getUnseenCount(supplierID, storeid: string,  lastSeen: string): Observable<string> {
  return this.http.get(`${this.url}photonotes/?SupplierID=${encodeURI(supplierID)}&storeid=${encodeURI(storeid)}&summary=${encodeURI(lastSeen)}&_ts=${+new Date}/`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}




getPhotoNoteFlags(): Observable<string> {
  return this.http.get(`${this.url}photonotes/?flags=1&_ts=${+new Date}`).pipe(
     
    map(results => {              
            return results['DataResult']; 
            
    }))
}

updateLastSeen(pnID:any, read:any): Promise<any> {

  return this.http.post(`${this.url}photonotes/?${encodeURI(pnID)}&${encodeURI(read)}&_ts=${+new Date}`, pnID, read).pipe(
     
    map(results => {      
      console.log('update read response ',results);        
            return results['DataResult']; 
            
    })).toPromise();}


  /**
   * 
   *  Cache Functions
   */

  async setSupplierStoreCacheData(storeID: string, apiCallName: string, data: any): Promise<any> {
      await this.getStorageValue('ActiveSupplier').then((res) => {
          console.debug('set & getCache: ActiveSupplier', res);
          console.debug('set & getCache: this.updateActiveSupplier', this.stored['ActiveSupplier']);
          this.storage.set(`_cacheApiData.${this.stored['ActiveSupplier']}.${storeID}.${apiCallName}`, data)
              .then(res =>{  
                  let cache = this.getSupplierStoreCacheData(storeID, apiCallName);
                  return Promise.resolve(cache);             
              
              })  // Set and Get.... See what it does, if we have this delay issue.
                  
          });           
  }

  async setSupplierStoreSkuCacheData(storeID: string, skuID: string, apiCallName: string, data: any): Promise<any> {
    await this.getStorageValue('ActiveSupplier').then((res) => {
        console.debug('set & getCache: ActiveSupplier', res);
        console.debug('set & getCache: this.updateActiveSupplier', this.stored['ActiveSupplier']);
        this.storage.set(`_cacheApiData.${this.stored['ActiveSupplier']}.${storeID}.${apiCallName}.${skuID}`, data)
            .then(res =>{  
                let cache = this.getSupplierStoreCacheData(storeID, apiCallName);
                return Promise.resolve(cache);             
            
            })  
                
        });           
}

  async getSupplierStoreCacheData(storeID: string, apiCallName: string): Promise<any> {

    let key: any;

    await this.getStorageValue('ActiveSupplier').then(() => {
            console.debug('getSupplierStoreCacheData storeID',storeID);
            console.debug('getSupplierStoreCacheData CallName',apiCallName);
            console.debug('getSupplierStoreCacheData ActiveSupplier',this.stored['ActiveSupplier']);
        
         key  = `_cacheApiData.${this.stored['ActiveSupplier']}.${storeID}.${apiCallName}`;
            console.debug('getSupplierStoreCacheData storage KEY',key);
    })

    return await this.storage.get(key).then((val) => {          
            console.debug('getSupplierStoreCacheData storage VAL', val);
          if( val != null && val != 'null' && val !== 'undefinded' ) {
            console.debug('getSupplierStoreCacheData storage VAL - (not NULL)', val);
            return this.cacheData[key] = JSON.parse(val);
           
          } else {
            console.debug('CACHE KEY NOT FOUND OR IS NULL - RETURN FALSE: ' + key + ' ', val); 
            return false;
         }
          
        }).catch((error) => {
            console.debug('Unable to access Storage [IS READY?] Storage get for ' + key + ' ', error);            
            return false;
        });   
  }

  async getSupplierStoreSkuCacheData(storeID: string, skuID:string, apiCallName: string): Promise<any> {

    let key: any;

    await this.getStorageValue('ActiveSupplier').then(() => {           
        
         key  = `_cacheApiData.${this.stored['ActiveSupplier']}.${storeID}.${apiCallName}.${skuID}`;            
    })

    return await this.storage.get(key).then((val) => {          
            
          if( val != null && val != 'null' && val !== 'undefinded' ) {            
            return this.cacheData[key] = JSON.parse(val);           
          } else {             
            return false;
         }
          
        }).catch((error) => {                       
            return false;
        });   
  }


  async getContactListCacheData(storeID: string, activeSupplier: string): Promise<any> {

    let key: any;          
        
    key  = `_cacheApiData.contactList.${activeSupplier}.${storeID}.`; 

    return await this.storage.get(key).then((val) => {          
            
          if( val != null && val != 'null' && val !== 'undefinded' ) {            
            return this.cacheData[key] = JSON.parse(val);           
          } else {             
            return false;
         }
          
        }).catch((error) => {                       
            return false;
        });   
  }


  async getContactList(storeid: string, channel: string): Promise<any> {

    //var apiCallName = 'contactList';

        await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function? 
            
        return await this.getContactListCacheData(storeid, this.stored['ActiveSupplier'])
            .then(results => {
              console.log('CONTACTS - Get from Cache/Check', results);  
                  if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) { 
                    console.log('CONTACTS - Found in Cache', results);
                    return results;
                
                  }  else {
                    console.log('CONTACTS - Get from API');
                        return this.http.get(`${this.url}getContactList/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&activesupplier=${this.stored['ActiveSupplier']}&_ts=${+new Date}`).pipe(
                           
                          map(results => { 
                                  return results['DataResult']; 
                                })).toPromise();
                              }
                          });
}





  async getStorageValue(key: string): Promise<any> {
    return await this.storage.get(key).then((val) => {
         console.log('Storage get: ' + key + ' ', val);          
         
          return Promise.resolve(this.stored[key] = val);          
        
      }).catch((error) => {
          console.log('ERROR: Storage get for ' + key + ' ', error);
      });
  }


  checkCacheExpire(cacheDataDate: string, channel: string) {

    var todayTimeStamp = +new Date; // Unix timestamp in milliseconds
    var oneDayTimeStamp = 1000 * 60 * 60 * 24; // Milliseconds in a day
    var diff = todayTimeStamp - oneDayTimeStamp;
    var yesterdayDate = new Date(diff);
    var yesterdayString = yesterdayDate.getFullYear() + '-' + (yesterdayDate.getMonth() + 1) + '-' + yesterdayDate.getDate();

    /*
    * Check if cacheDate == Yesterday
    * PNP should always be yesterday
    * if not - either no data or data not updated yet.
    * both cases api must be checked for update
    * COULD implement some sort of buffer, using a new "last checked" type of value. Risk is in the mornings could have delay in update. will revisit
    * 
    * SRCH data is weekly at the moment. 
    * 
    */

    if(yesterdayString != cacheDataDate) {

      // Cache delete for client/store


      
    }
  }


  
 

/*
  getCurrentStoreLocation() {

     this.selectedStore = null; 
   
     this.storelocationlookupService.searchData(this.geoLatitude, this.geoLongitude, this.geoAccuracy, this.id).toPromise().then(data => {
       this.selectedStore = data[0];

       this.storage.remove('STORE_ID'); 
       this.storage.set('STORE_ID', data[0]['id']); 
       this.storage.set('STORE_NAME', data[0]['StoreName']); 
       this.storage.set('STORE_CHANNEL', data[0]['Channel']); 
       this.storage.set('STORE_BANNER', data[0]['Banner']); 
       this.storage.set('SELECT_GEO_LAT', this.geoLatitude);
       this.storage.set('SELECT_GEO_LON', this.geoLongitude);
       this.storage.set('SELECT_GEO_ACC', this.geoAccuracy);
      });
   
    
};
*/





/*async getDataCacheParams() {
await this.getStorageValue('STORE_ID');
await this.getStorageValue('STORE_NAME');
await this.getStorageValue('STORE_CHANNEL'); 
await this.getStorageValue('STORE_BANNER');
await this.getStorageValue('SELECT_GEO_LAT');
await this.getStorageValue('SELECT_GEO_LON');
await this.getStorageValue('SELECT_GEO_ACC');
//return(1);    
              //this.stored
}
*/

  
          
   /*  
   return new Promise(function(resolve, reject) {
            setTimeout(function() {
                resolve('result of job 2');
            }, 1000);
        });

  */

          

                   
             
  

  /**
  * Get the detailed information for an ID using the "i" parameter
  * 
  * @param {string} id storeID to retrieve information
  * @returns Observable with detailed information
  */
 /**
  getDetails(id) {
    return this.http.get(`${this.url}?storeid=${id}`);
  }
  */

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Error',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  getStoreClientOrdersDeliverys(storeid: string, channel: string): Observable<string> {
    return this.http.get(`${this.url}orderdelivery/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=1&_ts=${+new Date}`).pipe(
      retry(3), 
      map(results => { 
              return results['DataResult']; 
      }))
  }

  async getStoreClientStockLevelss(storeid: string, channel: string, summary: string): Promise<any> {

    if(summary == '1') {  var apiCallName = 'storeClientStockLevelsSummary';
    } else {  var apiCallName = 'storeClientStockLevels'; };

  await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function?           
        return await this.getSupplierStoreCacheData(storeid, apiCallName)
            .then(results => {

                  if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) { 
                    return results;
                
                  }  else {
                        return this.http.get(`${this.url}stocklevels/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
                          retry(3), 
                          map(results => { 
                                  return results['DataResult']; 
                                })).toPromise();
                              }
                          });
} 

  async getStoreListingInfos(storeid: string, channel: string, summary: string): Promise<any> {

    if(summary == '1') {  var apiCallName = 'storeListingInfoSummary';
      } else {  var apiCallName = 'storeListingInfo'; };

    await this.getStorageValue('ActiveSupplier'); // @TODO? Get once and pass to function?           
    return await this.getSupplierStoreCacheData(storeid, apiCallName)
        .then(results => {

              if( results != null && results != 'null' && results !== ""  && results != false && results != 'false' ) { 
                return results;
            
              }  else {
  
                return this.http.get(`${this.url}storelistings/?storeid=${encodeURI(storeid)}&channel=${encodeURI(channel)}&summary=${encodeURI(summary)}&_ts=${+new Date}`).pipe(
                  retry(3), 
                        map(results => {                           
                              this.setSupplierStoreCacheData(storeid, apiCallName, JSON.stringify(results['DataResult']));                                                
                              return results['DataResult'];
                      })).toPromise();
            }
        });
}

postInstructionResponse(instructionID, storeID, supplierID, postData: any): Promise<any> {

  return this.http.post(`${this.url}/izonTasks/?instructionID=${encodeURI(instructionID)}&storeID=${encodeURI(storeID)}&supplierID=${encodeURI(supplierID)}`, postData).pipe(
    
        map(results => {
              console.log('Post Instruction Response API Response',results);
              return results['DataResult'];
        })).toPromise();

}

 
getTasks(storeId: string, supplierId: string): Observable<any> {
    return this.http.get(`${this.url}izonTasks/?list=1&storeID=${encodeURI(storeId)}&supplierID=${encodeURI(supplierId)}`).pipe(
       
      map(results => {              
              return results['DataResult']; 
      }))
  }
  getTask(storeId: string, supplierId: string, surveyId: string): Observable<any> {
    return this.http.get(`${this.url}izonTasks/?storeID=${encodeURI(storeId)}&supplierID=${encodeURI(supplierId)}&surveyID=${encodeURI(surveyId)}`).pipe(
       
      map(results => {              
              return results['DataResult']; 
      }))
  }



  
}
