import { Injectable } from "@angular/core";
import { isArray } from 'util';
import { Storage } from '@ionic/storage';
// import { isNullOrUndefined } from 'util';
// import { isNull } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: "root"
})
export class DataService {

  suppliers = [];
  activeSupplier: Array<any>;
  // activeSupplierName: string;
  // activeSupplierImg: string
  // activeSupplierID: number;
  

  constructor(private storage: Storage) { }

  filterItems(searchTerm, dataArray) {
     console.log('DATA SERVICE SEARCH TERM', searchTerm);
     console.log('DATA ARRAY', dataArray)
    if(!isArray(dataArray)) {
       console.log('DATA FILTER - Array is Null');
       return 0;
    } else {
    
        return dataArray.filter(item => {
        
            // return (item.ArticleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.Barcode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
            return (item.ArticleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1  || (item.customCategory.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));

        });
    }
  }

  filterListingItems(searchTerm, dataArray) {
    console.log('DATA SERVICE SEARCH TERM', searchTerm);
    console.log('DATA ARRAY', dataArray)
   if(!isArray(dataArray)) {
      console.log('DATA FILTER - Array is Null');
      return 0;
   } else {
   
       return dataArray.filter(item => {
       
           // return (item.ArticleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.Barcode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
           return (item.ArticleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || (item.SiteArticleStatus.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) || (item.customCategory.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));

       });
   }
 }

  filterPNs(searchTerm, dataArray) {
    console.log('DATA SERVICE SEARCH TERM', searchTerm);
    console.log('DATA ARRAY', dataArray)
   if(!isArray(dataArray)) {
      console.log('DATA FILTER - Array is Null');
      return 0;
   } else {
   
       return dataArray.filter(item => {
       console.log(item)
       if (item.pnData.articleDescription != undefined){
           // return (item.ArticleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.Barcode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
           return (item.pnData.articleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
       }
       });
   }
 }

 filterTNs(searchTerm, dataArray) {
  console.log('DATA SERVICE SEARCH TERM', searchTerm);
  console.log('DATA ARRAY', dataArray)
 if(!isArray(dataArray)) {
    console.log('DATA FILTER - Array is Null');
    return 0;
 } else {
 
     return dataArray.filter(item => {
     console.log(item)
     if (item.tnData.articleDescription != undefined){
         // return (item.ArticleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.Barcode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
         return (item.tnData.articleDescription.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
     }
     });
 }
}

  async getSuppliers() {
    let s = await this.storage.get('ACCOUNT_LinkedSuppliers').then(resp => {
      console.log('getSuppliers RESP', resp);
      let suppliers = JSON.parse(JSON.stringify(resp));
      return suppliers;
    });
    return s;
  }

  async getSupplierFromLinkedSuppliers(supplierID: any): Promise<any> {

      return await this.getSuppliers().then( data => {

        console.log('getSuppliers Result', data);
        for (const s of data) {
          // console.log('Linked Supplier Each',s);

          if (s['SupplierID'] === supplierID) {
            console.log('Supplier Found', s);
            //this.events.publish('activeSupplier',si);
            return Promise.resolve(s);                    
          }
          
        }
        // return Promise.reject('supplierID not in LinkedSuppliers');
      }).catch((error) => {
        console.error('ERROR: getSupplierFromLinkedSuppliers failed:', error);
        return Promise.reject('supplierID not in LinkedSuppliers');
    });;
    
  }
 
}